import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space10 } = theme();

const style = StyleSheet.create({
  button: {
    alignSelf: "auto",
    height: space10,
    width: space10,
  },
});

export { style };
