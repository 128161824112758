import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { returnCheckoutItem as returnCheckoutItemCommand } from "../../../../domain/checkoutItem/command/returnCheckoutItem";
import { Feedbacks } from "../../../../domain/checkoutItem/model/feedbacks";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface ReturnCheckoutItemFunctionArgs {
  readonly feedbacks: Feedbacks;
}

interface ReturnCheckoutItemFunction {
  (args: ReturnCheckoutItemFunctionArgs): Promise<void>;
}

interface UseReturnCheckoutItemFunctionArgs {
  readonly checkoutItemId: string;
  readonly logger: Logger;
}

type UseReturnCheckoutItemResult = [returnCheckoutItem: ReturnCheckoutItemFunction, status: CommandStatus];

interface UseReturnCheckoutItemFunction {
  (args: UseReturnCheckoutItemFunctionArgs): UseReturnCheckoutItemResult;
}

const useReturnCheckoutItem: UseReturnCheckoutItemFunction = ({ checkoutItemId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const returnCheckoutItem: ReturnCheckoutItemFunction = useCallback(
    async ({ feedbacks }) => {
      try {
        await commandBus(returnCheckoutItemCommand({ aggregateId: checkoutItemId, feedbacks }));
        createNotification({
          bodyI18nKey: I18nMessages.RETURN_QUESTION_TOAST_SUCCESS,
          level: NotificationLevel.SUCCESS,
        });
      } catch (error) {
        logger.captureException(error as Error);
        createNotification({
          level: NotificationLevel.ERROR,
          bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
        });
      }
    },
    [checkoutItemId, commandBus, createNotification, logger],
  );

  return [returnCheckoutItem, status];
};

export { useReturnCheckoutItem };
