import { StyleSheet, ViewStyle } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBorderActionSecondary, borderWidth2, borderStyle } = theme();

const style = StyleSheet.create({
  button: {
    borderColor: colorBorderActionSecondary,
    borderStyle: borderStyle,
    borderWidth: borderWidth2,
    flex: 1,
    marginHorizontal: 12,
  } as ViewStyle,
});

export { style };
