import { useCallback } from "react";
import { PixelRatio } from "react-native";

const DEFAULT_DPI = 1;
const HIGHDPI = 2;

interface CdnImageUrlParameters {
  url: string;
  width: number;
  dpi?: number;
}

const useMediaImage = () => {
  const cdnImageUrl = useCallback(({ url, width, dpi = PixelRatio.get() }: CdnImageUrlParameters) => {
    const imageDpi = dpi > DEFAULT_DPI ? HIGHDPI : DEFAULT_DPI;

    return `${url}?w=${Math.ceil(width * imageDpi)}&f=auto`;
  }, []);

  return cdnImageUrl;
};

export { useMediaImage };
