import { BaseTrackingEvent, TrackingEventCategory } from "@lookiero/sty-psp-tracking";
import { CheckoutItemStatus } from "../../domain/checkoutItem/model/checkoutItem";
import { Currency } from "../../domain/checkoutItem/model/currency";
import { MediaPerspective } from "../../projection/checkoutItem/checkoutItem";
import { Subscription } from "../../projection/subscription/subscription";

const PROJECT = "checkout";

enum TrackingPage {
  ITEM = "item",
  CHECKOUT = "checkout",
  SUMMARY = "summary",
  FEEDBACK = "feedback",
  RETURN = "return",
}

enum TrackingEventName {
  PAGEVIEW = "pageview",
  KEEP_ITEM = "keep_item",
  RETURN_ITEM = "return_item",
  REPLACE_ITEM = "replace_item",
  RESET_ITEM = "reset_item",
  IMAGE_VIEW = "image_view",
  PRESS_PRICING = "press_pricing",
  PRESS_ITEM = "press_item",
  PRESS_CONTINUE = "press_continue",
  TAB_VIEW = "tab_view",
  CHANGE_FEEDBACK = "change_feedback",
  PRESS_MENU = "press_menu",
  PRESS_BACK = "press_back",
  PRESS_NEXT = "press_next",
  PRESS_PREVIOUS = "press_previous",
  CHECKOUT = "checkout",
  AB_TEST = "abtest",
}

interface CheckoutBaseTrackingEvent<
  EN extends TrackingEventName,
  EC extends TrackingEventCategory = TrackingEventCategory.NAVIGATION,
> extends BaseTrackingEvent<EN, EC> {
  readonly checkoutId: string;
}

type PageViewTrackingEvent = CheckoutBaseTrackingEvent<TrackingEventName.PAGEVIEW>;

interface ItemPageViewTrackingEvent extends PageViewTrackingEvent {
  readonly checkoutItemId: string;
  readonly status: CheckoutItemStatus;
  readonly productVariantId: string;
  readonly replaceableFor: string;
  readonly unique: boolean;
}

interface KeepItemTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.KEEP_ITEM> {
  readonly checkoutItemId: string;
}

interface ReturnItemTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.RETURN_ITEM> {
  readonly checkoutItemId: string;
}

interface ReplaceItemTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.REPLACE_ITEM> {
  readonly checkoutItemId: string;
  readonly productVariantId: string;
  readonly replaceFor: string;
}

interface ResetItemTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.RESET_ITEM> {
  readonly checkoutItemId: string;
}

interface ImageViewTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.IMAGE_VIEW> {
  readonly checkoutItemId: string;
  readonly productVariantId: string;
  readonly perspective: MediaPerspective;
}

interface PressPricingTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.PRESS_PRICING> {
  readonly collapse: boolean;
}

interface PressItemTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.PRESS_ITEM> {
  readonly checkoutItemId: string;
}

type PressContinueTrackingEvent = CheckoutBaseTrackingEvent<TrackingEventName.PRESS_CONTINUE>;

interface TabViewTrackingEvent<T> extends CheckoutBaseTrackingEvent<TrackingEventName.TAB_VIEW> {
  readonly tab: T;
}

interface ChangeFeedbackTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.CHANGE_FEEDBACK> {
  readonly questionId: string;
  readonly questionName: string;
  readonly feedback: string | undefined;
}

interface PressMenuTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.PRESS_MENU> {
  readonly collapse: boolean;
}

type PressBackTrackingEvent = CheckoutBaseTrackingEvent<TrackingEventName.PRESS_BACK>;

interface PressNextTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.PRESS_NEXT> {
  readonly from: string;
  readonly to: string;
}

interface PressPreviousTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.PRESS_PREVIOUS> {
  readonly from: string;
  readonly to: string;
}

interface CheckoutTrackingEvent
  extends CheckoutBaseTrackingEvent<TrackingEventName.CHECKOUT, TrackingEventCategory.ECOMMERCE> {
  readonly userId: string;
  readonly sizeChanges: number;
  readonly isFirstOrder: boolean;
  readonly ecommerce: {
    readonly checkout: {
      readonly actionField: {
        readonly items: number;
        readonly currencyCode: Currency;
        readonly subscription: Subscription;
        readonly coupon: string | null;
        readonly orderId: number;
        readonly value: number;
      };
    };
  };
}

interface ABTestTrackingEvent extends CheckoutBaseTrackingEvent<TrackingEventName.AB_TEST> {
  readonly experiment: string;
  readonly variation: string;
}

export type {
  BaseTrackingEvent,
  ChangeFeedbackTrackingEvent,
  CheckoutTrackingEvent,
  ImageViewTrackingEvent,
  ItemPageViewTrackingEvent,
  KeepItemTrackingEvent,
  PageViewTrackingEvent,
  PressContinueTrackingEvent,
  PressItemTrackingEvent,
  PressPricingTrackingEvent,
  ReplaceItemTrackingEvent,
  ResetItemTrackingEvent,
  ReturnItemTrackingEvent,
  TabViewTrackingEvent,
  PressMenuTrackingEvent,
  PressBackTrackingEvent,
  PressNextTrackingEvent,
  PressPreviousTrackingEvent,
  ABTestTrackingEvent,
};

export { PROJECT, TrackingPage, TrackingEventName, TrackingEventCategory };
