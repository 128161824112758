import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
import { HEADER_HEIGHT } from "../../components/templates/header/Header.style";

const { colorBgPrimaryLight, space12 } = theme();

const style = StyleSheet.create({
  fiveItemsDiscountPadding: {
    paddingTop: space12,
  },
  header: {
    height: HEADER_HEIGHT,
  },
  headerWithDoubleHeight: {
    height: HEADER_HEIGHT * 2,
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  safeAreaView: {
    backgroundColor: colorBgPrimaryLight,
  },
});

export { style };
