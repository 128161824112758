import React, { FC } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nNumber } from "@lookiero/i18n-react";
import { PriceProjection } from "../../../../../../projection/price/price";
import { style } from "./Price.style";

interface PriceProps {
  readonly price: PriceProjection;
  readonly withPercentage?: boolean;
}
const Price: FC<PriceProps> = ({ price, withPercentage = false }) => {
  const isDiscounted = price.discountedPrice && price.discountedPrice.percentage > 0;
  const productPrice = useI18nNumber({
    value: price.amount / 100,
    style: "currency",
    currency: price.currency,
  });
  const productDiscountedPrice = useI18nNumber({
    value: (price.discountedPrice?.amount || 0) / 100,
    style: "currency",
    currency: price.currency,
  });

  const priceValue = isDiscounted ? productDiscountedPrice : productPrice;

  return (
    <View style={style.price} testID="price">
      {isDiscounted && (
        <Text level={1} style={style.discountedPriceText} testID="discounted-price-text" detail lineThrough>
          {productPrice}
        </Text>
      )}
      <View style={style.discountedPriceInfo}>
        <Text level={1} testID="price-text" detail>
          {priceValue}
        </Text>
        {isDiscounted && withPercentage ? (
          <Text level={1} style={style.discountedPercentage} testID="discounted-percentage-text" detail>
            {`-${price.discountedPrice.percentage}%`}
          </Text>
        ) : null}
      </View>
    </View>
  );
};

export { Price };
