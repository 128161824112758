import React, { FC } from "react";
import { ImageStyle, Pressable, StyleProp, View } from "react-native";
import { COLOR, Icon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Country } from "@lookiero/sty-psp-locale";
import { LazyImage } from "@lookiero/sty-psp-ui";
import { CheckoutItemStatus } from "../../../../../../domain/checkoutItem/model/checkoutItem";
import { ColorProjection, MediaProjection } from "../../../../../../projection/checkoutItem/checkoutItem";
import { PriceProjection } from "../../../../../../projection/price/price";
import { size, SizeProjection } from "../../../../../../projection/size/size";
import { Price } from "../../../../components/atoms/price/Price";
import { useMediaImage } from "../../../../hooks/useMediaImage";
import { COLOR_I18N_PREFIX, I18nMessages } from "../../../../i18n/i18n";
import { IMAGE_WIDTH, style } from "./ProductVariant.style";

interface ProductVariantStyle {
  readonly image: StyleProp<ImageStyle>;
}

interface ProductVariantProps {
  readonly media: MediaProjection[];
  readonly brand: string;
  readonly name: string;
  readonly price: PriceProjection;
  readonly size: SizeProjection;
  readonly color: ColorProjection;
  readonly status?: CheckoutItemStatus;
  readonly country: Country;
  readonly style?: Partial<ProductVariantStyle>;
  readonly onPress?: () => void;
}
const ProductVariant: FC<ProductVariantProps> = ({
  media,
  brand,
  name,
  price,
  size: sizeProjection,
  color,
  status,
  country,
  style: customStyle,
  onPress,
}) => {
  const uniqueText = useI18nMessage({ id: I18nMessages.ITEM_UNIQUE });
  const colorLabel = useI18nMessage({ id: color.name, prefix: COLOR_I18N_PREFIX });
  const sizeChangeText = useI18nMessage({ id: I18nMessages.PRODUCT_VARIANT_SIZE_CHANGE });

  const cdnImageUrl = useMediaImage();

  return (
    <Pressable
      pointerEvents={onPress ? "auto" : "none"}
      style={style.container}
      testID="product-variant"
      onPress={onPress}
    >
      <View style={style.row}>
        <View>
          <LazyImage
            hiResSrc={cdnImageUrl({ url: media[0]?.url as string, width: IMAGE_WIDTH })}
            resizeMode="contain"
            src={cdnImageUrl({ url: media[0]?.url as string, width: IMAGE_WIDTH, dpi: 1 })}
            style={{ view: [style.media, customStyle?.image] }}
            testID="product-variant-media"
          />
        </View>

        <View style={style.descriptionContainer}>
          <View style={style.infoProductVariant}>
            <Text color={COLOR.TEXT_MEDIUM} level={2} detail>
              {brand}
            </Text>
            <Text level={2} detail>
              {name}
            </Text>
            <Text color={COLOR.TEXT_MEDIUM} level={2} detail>
              {sizeProjection.unique ? uniqueText : size({ size: sizeProjection, country })} / {colorLabel}
            </Text>

            {status === CheckoutItemStatus.REPLACED && (
              <View style={style.sizeChange}>
                <Text level={2} detail>
                  {sizeChangeText}
                </Text>
              </View>
            )}
          </View>

          <Price price={price} variant="detail" />
        </View>
      </View>

      {onPress && <Icon name="arrow_right" />}
    </Pressable>
  );
};

export type { ProductVariantStyle };
export { ProductVariant };
