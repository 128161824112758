import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, space1 } = theme();

const style = StyleSheet.create({
  fieldBackground: {
    backgroundColor: colorBgBase,
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  fieldText: {
    flexWrap: "nowrap",
    overflow: "hidden",
    paddingHorizontal: space1,
    zIndex: 1,
  },
});

export { style };
