import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, PressNextTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface PressNextFunctionArgs {
  readonly from: string;
  readonly to: string;
}
interface PressNextFunction {
  (args: PressNextFunctionArgs): void;
}

interface UseTrackPressNextFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}

interface UseTrackPressNextFunction {
  (args: UseTrackPressNextFunctionArgs): PressNextFunction;
}

const useTrackPressNext: UseTrackPressNextFunction = ({ page, country, segment, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<PressNextTrackingEvent>();

  const pressNext: PressNextFunction = useCallback(
    ({ from, to }) => {
      if (!checkoutId) {
        return;
      }

      const pressNextTrackingEvent: PressNextTrackingEvent = {
        event: TrackingEventName.PRESS_NEXT,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        from,
        to,
      };

      emitUserEvent(pressNextTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, page, segment],
  );

  return pressNext;
};

export { useTrackPressNext };
