import React, { FC, useCallback } from "react";
import { Pressable } from "react-native";
import { SvgProps } from "react-native-svg";
import invariant from "tiny-invariant";
import { theme } from "@lookiero/sty-psp-ui";
import { useCheckoutQuestionFeedbackForId } from "../../behaviors/useCheckoutQuestionFeedback";
import { CheckoutQuestionItem, CheckoutQuestionItemProps } from "../CheckoutQuestionItem";
import { style } from "./IconCheckoutQuestionItem.style";
import { Happy } from "./icons/Happy";
import { Normal } from "./icons/Normal";
import { Sad } from "./icons/Sad";

const { colorBgPrimaryMediumLight } = theme();

type IconName = "checkout_question.icon.bad" | "checkout_question.icon.regular" | "checkout_question.icon.good";

const ICON: Record<IconName, FC<SvgProps>> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "checkout_question.icon.bad": Sad,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "checkout_question.icon.regular": Normal,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "checkout_question.icon.good": Happy,
};

const IconCheckoutQuestionItem: CheckoutQuestionItem = ({
  checkoutQuestion,
  checkoutQuestionParentId,
  testID,
}: CheckoutQuestionItemProps) => {
  const Icon = ICON[checkoutQuestion.name as IconName];

  const { feedback, onChange } = useCheckoutQuestionFeedbackForId({ id: checkoutQuestionParentId });
  const isSelected = feedback === checkoutQuestion.id;

  const handleOnPress = useCallback(
    () => onChange({ checkoutQuestionId: checkoutQuestionParentId, checkoutQuestionFeedback: checkoutQuestion.id }),
    [onChange, checkoutQuestion.id, checkoutQuestionParentId],
  );

  invariant(Icon, "CheckoutQuestion icon does not exist");

  return (
    <Pressable accessibilityLabel={testID} testID={testID} onPress={handleOnPress}>
      <Icon
        fill={isSelected ? colorBgPrimaryMediumLight : "none"}
        strokeWidth={isSelected ? 2 : 1}
        style={style.icon}
      />
    </Pressable>
  );
};

export type { IconName };
export { IconCheckoutQuestionItem };
