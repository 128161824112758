import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { generatePath, useMatch, useNavigate, useParams } from "react-router-native";
import { Box, Spinner } from "@lookiero/aurora";
import { QueryStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { Layout } from "@lookiero/sty-psp-ui";
import { CheckoutItemStatus } from "../../../../domain/checkoutItem/model/checkoutItem";
import {
  BookedProductsVariantsProjection,
  ProductVariantProjection,
} from "../../../../projection/bookedProductsVariants/bookedProductsVariants";
import { CheckoutItemProjection } from "../../../../projection/checkoutItem/checkoutItem";
import { useBookCheckoutBookingForCheckoutItem } from "../../../domain/checkoutBooking/react/useBookCheckoutBookingForCheckoutItem";
import { useViewBookedProductsVariantsForCheckoutItem } from "../../../projection/bookedProductsVariants/react/useViewBookedProductsVariantsForCheckoutItem";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { useViewFiveItemsDiscountByCustomerId } from "../../../projection/checkout/react/useViewFiveItemsDiscountByCustomerId";
import { useViewIsSizeChangeEnabledByCheckoutId } from "../../../projection/checkout/react/useViewIsSizeChangeEnabledByCheckoutId";
import { useListReturnQuestionsByCheckoutItemId } from "../../../projection/returnQuestion/react/useListReturnQuestionsByCheckoutItemId";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackItemPageView } from "../../../tracking/useTrackItemPageView";
import { useTrackPressBack } from "../../../tracking/useTrackPressBack";
import { useTrackPressNext } from "../../../tracking/useTrackPressNext";
import { useTrackPressPrevious } from "../../../tracking/useTrackPressPrevious";
import { FiveItemsDiscountBanner } from "../../components/atoms/fiveItemsDiscountBanner/FiveItemsDiscountBanner";
import { ReturnQuestionFeedbackProvider } from "../../components/organisms/returnQuestions/behaviors/useReturnQuestionFeedback";
import { ItemDetailHeader } from "../../components/templates/header/itemDetailHeader/ItemDetailHeader";
import { ItemHeader } from "../../components/templates/header/itemHeader/ItemHeader";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { Routes } from "../../routing/routes";
import { useBasePath } from "../../routing/useBasePath";
import { style } from "./Item.style";
import {
  CheckoutItemWithCustomerDecission,
  ItemWithCustomerDecission,
} from "./views/itemWithCustomerDecission/ItemWithCustomerDecission";
import {
  CheckoutItemWithoutCustomerDecission,
  ItemWithoutCustomerDecission,
} from "./views/itemWithoutCustomerDecission/ItemWithoutCustomerDecission";

interface HandleOnNextFunctionArgs {
  readonly from: string;
  readonly to: string;
}
interface HandleOnNextFunction {
  (args: HandleOnNextFunctionArgs): void;
}
interface HandleOnPreviousFunctionArgs {
  readonly from: string;
  readonly to: string;
}
interface HandleOnPreviousFunction {
  (args: HandleOnPreviousFunctionArgs): void;
}

interface ItemProps {
  readonly layout: Layout;
}

const Item: FC<ItemProps> = ({ layout: Layout }) => {
  const logger = useLogger();
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const basePath = useBasePath();
  const {
    customer: { customerId, country, segment },
  } = useStaticInfo();
  const isDesktopScreen = screenSize !== "S";

  const { id } = useParams();
  const [fiveItemsDiscount = 0, fiveItemsDiscountStatus] = useViewFiveItemsDiscountByCustomerId({ customerId });
  const [checkout, checkoutStatus] = useViewFirstAvailableCheckoutByCustomerId({ customerId });
  const checkoutItem = checkout?.items.find((checkoutItem) => checkoutItem.id === id) as CheckoutItemProjection;

  /* SizeChange and Booking */
  const [isSizeChangeEnabled, sizeChangeEnabledStatus] = useViewIsSizeChangeEnabledByCheckoutId({
    checkoutId: checkout?.id,
  });
  const [bookedProductsVariants, bookedProductsVariantsStatus] = useViewBookedProductsVariantsForCheckoutItem({
    checkoutItemId: checkoutItem.id,
  });
  const isBookingRequired = bookedProductsVariants === null && isSizeChangeEnabled;
  const [bookCheckoutBooking] = useBookCheckoutBookingForCheckoutItem({
    checkoutItemId: checkoutItem.id,
    checkoutBookingId: checkout?.checkoutBookingId,
    logger,
  });
  // This reference is used for the effect not be triggered twice
  // (bookCheckoutBooking changes when the checkout has a proper checkoutBookingId)
  const bookCheckoutBookingRef = useRef(bookCheckoutBooking);
  bookCheckoutBookingRef.current = bookCheckoutBooking;
  useEffect(() => {
    if (isBookingRequired) {
      bookCheckoutBookingRef.current();
    }
  }, [isBookingRequired]);
  /* SizeChange and Booking */

  /* ReturnCheckoutItem */
  const [returnQuestions] = useListReturnQuestionsByCheckoutItemId({
    checkoutItemId: checkoutItem.id,
  });
  const goToReturnPage = useCallback(() => {
    navigate(`${basePath}/${generatePath(Routes.RETURN, { id: checkoutItem.id })}`);
  }, [basePath, checkoutItem.id, navigate]);
  /* ReturnCheckoutItem */

  const currentProductVariant: ProductVariantProjection = useMemo(
    () =>
      checkoutItem.status === CheckoutItemStatus.REPLACED && checkoutItem.replacedFor
        ? { id: checkoutItem.replacedFor.id, size: checkoutItem.replacedFor.size }
        : { id: checkoutItem.productVariant.id, size: checkoutItem.productVariant.size },
    [checkoutItem.productVariant.id, checkoutItem.productVariant.size, checkoutItem.replacedFor, checkoutItem.status],
  );

  const replaceableFor = useMemo(
    () =>
      sizeChangeEnabledStatus === QueryStatus.SUCCESS
        ? isSizeChangeEnabled
          ? bookedProductsVariants
            ? bookedProductsVariants.productVariants
                .filter(({ id }) => id !== currentProductVariant.id)
                .map(({ size }) => size.lookiero)
            : undefined
          : []
        : undefined,
    [bookedProductsVariants, currentProductVariant.id, isSizeChangeEnabled, sizeChangeEnabledStatus],
  );
  useTrackItemPageView({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId: checkout?.id,
    checkoutItemId: checkoutItem.id,
    status: checkoutItem.status,
    productVariantId: checkoutItem.productVariant.id,
    replaceableFor,
    unique: checkoutItem.productVariant.size.unique,
  });

  const trackPressNext = useTrackPressNext({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnNext: HandleOnNextFunction = useCallback(
    ({ from, to }) => trackPressNext({ from, to }),
    [trackPressNext],
  );
  const trackPressPrevious = useTrackPressPrevious({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnPrevious: HandleOnPreviousFunction = useCallback(
    ({ from, to }) => trackPressPrevious({ from, to }),
    [trackPressPrevious],
  );
  const trackPressBack = useTrackPressBack({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnBack = useCallback(() => {
    trackPressBack();
    navigate(-1);
  }, [navigate, trackPressBack]);
  const itemDetailRouteMatch = useMatch(`${basePath}/${Routes.ITEM_DETAIL}`);

  const header = useMemo(
    () => (
      <Box style={style.headerWrapper}>
        {itemDetailRouteMatch ? (
          <ItemDetailHeader onBack={handleOnBack} />
        ) : (
          <ItemHeader
            item={checkoutItem}
            items={checkout?.items as CheckoutItemProjection[]}
            onNext={handleOnNext}
            onPrevious={handleOnPrevious}
          />
        )}
        {fiveItemsDiscount !== 0 ? <FiveItemsDiscountBanner fiveItemsDiscount={fiveItemsDiscount} /> : null}
      </Box>
    ),
    [
      checkout?.items,
      checkoutItem,
      fiveItemsDiscount,
      handleOnBack,
      handleOnNext,
      handleOnPrevious,
      itemDetailRouteMatch,
    ],
  );

  const itemWithoutCustomerDecission = checkoutItem.status === CheckoutItemStatus.INITIAL;

  const dependenciesLoaded =
    checkoutStatus !== QueryStatus.LOADING &&
    checkout &&
    checkoutItem &&
    returnQuestions &&
    // This will ensure that the view is not shown until bookCheckoutBooking use-case is dispatched (if required)
    bookedProductsVariantsStatus !== QueryStatus.LOADING &&
    fiveItemsDiscountStatus !== QueryStatus.LOADING &&
    sizeChangeEnabledStatus !== QueryStatus.LOADING &&
    (isBookingRequired ? checkout.checkoutBookingId && bookedProductsVariants : true);

  if (!dependenciesLoaded) {
    return <Spinner testID="spinner" />;
  }

  return (
    <ReturnQuestionFeedbackProvider key={checkoutItem.id} feedback={checkoutItem.feedbacks || {}}>
      <Layout
        footer={null}
        header={header}
        scrollEnabled={!itemWithoutCustomerDecission}
        style={{
          header: fiveItemsDiscount !== 0 ? style.headerWithDoubleHeight : style.header,
          scrollView: { height: itemWithoutCustomerDecission ? "100%" : "auto" },
          safeAreaView: isDesktopScreen ? style.safeAreaView : null,
        }}
      >
        {itemWithoutCustomerDecission ? (
          <ItemWithoutCustomerDecission
            bookedProductsVariants={bookedProductsVariants as BookedProductsVariantsProjection}
            checkoutId={checkout.id}
            checkoutItem={checkoutItem as CheckoutItemWithoutCustomerDecission}
            currentProductVariant={currentProductVariant}
            onReturn={goToReturnPage}
          />
        ) : (
          <ItemWithCustomerDecission
            checkoutId={checkout.id}
            checkoutItem={checkoutItem as CheckoutItemWithCustomerDecission}
            currentProductVariant={currentProductVariant}
            returnQuestions={returnQuestions}
            onEditFeedback={goToReturnPage}
          />
        )}
      </Layout>
    </ReturnQuestionFeedbackProvider>
  );
};

export { Item };
