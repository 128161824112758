import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgPrimaryLight, space6, space8 } = theme();
const containerUnderlayColor = colorBgPrimaryLight;

const style = StyleSheet.create({
  content: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: space8,
  },
  title: {
    marginBottom: space6,
  },
});

export { style, containerUnderlayColor };
