import React, { FC, useCallback } from "react";
import { View } from "react-native";
import { Spinner } from "@lookiero/aurora";
import { CommandStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { CheckoutItemStatus } from "../../../../../../domain/checkoutItem/model/checkoutItem";
import { ProductVariantProjection } from "../../../../../../projection/bookedProductsVariants/bookedProductsVariants";
import { CheckoutItemProjection } from "../../../../../../projection/checkoutItem/checkoutItem";
import { ReturnQuestionProjection } from "../../../../../../projection/returnQuestion/returnQuestion";
import { useResetCheckoutItem } from "../../../../../domain/checkoutItem/react/useResetCheckoutItem";
import { TrackingPage } from "../../../../../tracking/tracking";
import { useTrackResetItem } from "../../../../../tracking/useTrackResetItem";
import { Body } from "../../../../components/layouts/body/Body";
import { useStaticInfo } from "../../../../hooks/useStaticInfo";
import {
  CustomerDecissionBanner,
  CustomerDecissionBannerStatus,
} from "../../components/banner/CustomerDecissionBanner";
import { ReturnQuestionsFeedback } from "../../components/returnQuestionsFeedback/ReturnQuestionsFeedback";
import { ProductVariant } from "../../views/productVariant/ProductVariant";
import { style } from "./ItemWithCustomerDecission.style";

interface CheckoutItemWithCustomerDecission extends CheckoutItemProjection {
  readonly status: Exclude<CheckoutItemStatus, CheckoutItemStatus.INITIAL>;
}

interface ItemWithCustomerDecissionProps {
  readonly checkoutId: string;
  readonly checkoutItem: CheckoutItemWithCustomerDecission;
  readonly returnQuestions: ReturnQuestionProjection[];
  readonly currentProductVariant: ProductVariantProjection;
  readonly onEditFeedback: () => void;
}

const ItemWithCustomerDecission: FC<ItemWithCustomerDecissionProps> = ({
  checkoutId,
  checkoutItem,
  returnQuestions,
  currentProductVariant,
  onEditFeedback,
}) => {
  const screenSize = useScreenSize();
  const isMobile = screenSize === "S";
  const logger = useLogger();
  const {
    customer: { country, segment },
  } = useStaticInfo();

  /* ResetCheckoutItem */
  const [resetCheckoutItem, resetCheckoutItemStatus] = useResetCheckoutItem({
    checkoutItemId: checkoutItem.id,
    logger,
  });
  const trackResetItem = useTrackResetItem({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId,
    checkoutItemId: checkoutItem.id,
  });
  const resetItem = useCallback(async () => {
    resetCheckoutItem();
    trackResetItem();
  }, [resetCheckoutItem, trackResetItem]);
  /* ResetCheckoutItem */

  const dependenciesLoaded = resetCheckoutItemStatus !== CommandStatus.LOADING;

  if (!dependenciesLoaded) {
    return <Spinner testID="spinner" />;
  }

  return (
    <Body style={{ row: style.container }}>
      <ProductVariant
        checkoutId={checkoutId}
        checkoutItem={checkoutItem}
        country={country}
        currentProductVariant={currentProductVariant}
        segment={segment}
        customerDecissionBanner={
          <CustomerDecissionBanner
            checkoutItemStatus={checkoutItem.status as CustomerDecissionBannerStatus}
            onPress={resetItem}
          />
        }
      >
        {checkoutItem.status === CheckoutItemStatus.RETURNED && (
          <View style={[style.feedbackContainer, isMobile ? style.feedbackContainerMobile : undefined]}>
            <ReturnQuestionsFeedback returnQuestions={returnQuestions} onEditFeedback={onEditFeedback} />
          </View>
        )}
      </ProductVariant>
    </Body>
  );
};

export type { CheckoutItemWithCustomerDecission };
export { ItemWithCustomerDecission };
