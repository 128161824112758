import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { MediaPerspective } from "../../projection/checkoutItem/checkoutItem";
import { ImageViewTrackingEvent, PROJECT, TrackingEventName, TrackingPage } from "./tracking";

interface ImageViewFunctionArgs {
  readonly perspective: MediaPerspective;
}
interface ImageViewFunction {
  (args: ImageViewFunctionArgs): void;
}

interface UseTrackImageViewFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
  readonly checkoutItemId: string;
  readonly productVariantId: string;
}
interface UseTrackImageViewFunction {
  (agrs: UseTrackImageViewFunctionArgs): ImageViewFunction;
}

const useTrackImageView: UseTrackImageViewFunction = ({
  page,
  country,
  segment,
  checkoutId,
  checkoutItemId,
  productVariantId,
}) => {
  const emitUserEvent = useEmitUserEvent<ImageViewTrackingEvent>();

  const imageView: ImageViewFunction = useCallback(
    ({ perspective }) => {
      if (!checkoutId) {
        return;
      }

      const imageViewTrackingEvent: ImageViewTrackingEvent = {
        event: TrackingEventName.IMAGE_VIEW,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        checkoutItemId,
        productVariantId,
        perspective,
      };

      emitUserEvent(imageViewTrackingEvent);
    },
    [checkoutId, checkoutItemId, country, emitUserEvent, page, productVariantId, segment],
  );

  return imageView;
};

export { useTrackImageView };
