import React, { FC } from "react";
import { Image } from "react-native";
import { COLOR, Text, View, useDevice } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Country } from "@lookiero/sty-psp-locale";
import { Column, Row } from "@lookiero/sty-psp-ui";
import { CheckoutItemProjection } from "../../../../../../projection/checkoutItem/checkoutItem";
import { size } from "../../../../../../projection/size/size";
import { useMediaImage } from "../../../../hooks/useMediaImage";
import { I18nMessages } from "../../../../i18n/i18n";
import { Price } from "../price/Price";
import { IMAGE_SIZE, style } from "./ProductVariantPreview.style";

interface ProductVariantPreviewProps {
  readonly item: CheckoutItemProjection;
  readonly country: Country;
}

const ProductVariantPreview: FC<ProductVariantPreviewProps> = ({ item, country }) => {
  const cdnImageUrl = useMediaImage();
  const { screen } = useDevice();

  const sizeText = useI18nMessage({ id: I18nMessages.ITEM_SIZE });
  const uniqueText = useI18nMessage({ id: I18nMessages.ITEM_UNIQUE });

  const {
    productVariant: { media, brand, name, size: sizeProjection },
    price,
  } = item;

  const mainImage = media.find((mediaElement) => mediaElement.perspective === "MAIN");

  return (
    <View style={[style.wrapper, !screen.S && style.mobileWrapper]} testID="product-variant-preview">
      <Row style={style.row}>
        <Column size={{ M: "2/3", L: "2/3" }} style={style.content}>
          {mainImage && (
            <Image
              resizeMode="stretch"
              style={style.image}
              testID="product-variant-image"
              source={{
                uri: cdnImageUrl({
                  url: mainImage.url,
                  width: IMAGE_SIZE,
                }),
              }}
            />
          )}

          <View style={style.info}>
            <Text color={COLOR.TEXT_MEDIUM} level={2} detail>
              {brand}
            </Text>
            <Text level={2} detail>
              {name}
            </Text>
            <Text color={COLOR.TEXT_MEDIUM} level={2} detail>
              {`${sizeText} ${sizeProjection.unique ? uniqueText : size({ size: sizeProjection, country })}`}
            </Text>
          </View>

          <View style={style.price}>
            <Price price={price} withPercentage />
          </View>
        </Column>
      </Row>
    </View>
  );
};

export { ProductVariantPreview };
