import { Platform, StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
import { HEADER_HEIGHT } from "../../components/templates/header/Header.style";

const {
  colorTextMedium,
  colorText,
  colorBgPrimaryLight,
  space2,
  space3,
  space4,
  space6,
  space12,
  space16,
  borderRadius4,
  colorBgBase,
} = theme();

const style = StyleSheet.create({
  contentWrapper: {
    backgroundColor: colorBgBase,
    flexGrow: 0,
  },
  description: {
    color: colorTextMedium,
    marginBottom: space6,
  },
  desktopContentWrapper: {
    borderRadius: borderRadius4,
    paddingHorizontal: space6,
    paddingVertical: space12,
  },
  desktopLayoutSpacing: {
    paddingVertical: space12,
  },
  desktopListSpacing: {
    paddingRight: space16,
  },
  desktopResume: {
    borderRadius: borderRadius4,
  },
  header: {
    height: HEADER_HEIGHT,
  },
  mobileInfo: {
    paddingHorizontal: space6,
    paddingTop: space6,
  },
  mobileResume: {
    shadowColor: colorText,
    shadowOpacity: 0.15,
    ...Platform.select({
      web: {
        shadowOffset: { width: 0, height: 8 },
        shadowRadius: 18,
        elevation: 0.5,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any,
  princingWrapper: {
    padding: space6,
  },
  resume: {
    overflow: "hidden",
    backgroundColor: colorBgBase,
    ...Platform.select({
      web: {
        position: "sticky",
        top: space12,
        alignSelf: "flex-start",
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any,
  scrollView: {
    backgroundColor: colorBgPrimaryLight,
    flex: 1,
  },
  sticky: {
    paddingBottom: space4,
    paddingTop: space3,
  },
  title: {
    marginBottom: space2,
  },
});

export { style };
