import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space6, space8 } = theme();

const style = StyleSheet.create({
  content: {
    marginBottom: space8,
  },
  title: {
    marginBottom: space6,
  },
});

export { style };
