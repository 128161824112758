import React, { FC, useCallback, useState } from "react";
import { LayoutRectangle, Platform, ScrollView } from "react-native";
import { Spinner } from "@lookiero/aurora";
import { CommandStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { theme } from "@lookiero/sty-psp-ui";
import { CheckoutItemStatus } from "../../../../../../domain/checkoutItem/model/checkoutItem";
import {
  BookedProductsVariantsProjection,
  ProductVariantProjection,
} from "../../../../../../projection/bookedProductsVariants/bookedProductsVariants";
import "../../../../../../projection/checkout/viewFirstAvailableCheckoutByCustomerId";
import { CheckoutItemProjection } from "../../../../../../projection/checkoutItem/checkoutItem";
import { useKeepCheckoutItem } from "../../../../../domain/checkoutItem/react/useKeepCheckoutItem";
import { useReplaceCheckoutItem } from "../../../../../domain/checkoutItem/react/useReplaceCheckoutItem";
import { TrackingPage } from "../../../../../tracking/tracking";
import { useTrackKeepItem } from "../../../../../tracking/useTrackKeepItem";
import { useTrackReplaceItem } from "../../../../../tracking/useTrackReplaceItem";
import { Body } from "../../../../components/layouts/body/Body";
import { useStaticInfo } from "../../../../hooks/useStaticInfo";
import { ItemActions } from "../../components/itemActions/ItemActions";
import { SizeWithoutStockModal } from "../../components/sizeWithoutStockModal/SizeWithoutStockModal";
import { ProductVariant } from "../productVariant/ProductVariant";
import { style } from "./ItemWithoutCustomerDecission.style";

interface CheckoutItemWithoutCustomerDecission extends CheckoutItemProjection {
  readonly status: CheckoutItemStatus.INITIAL;
}

interface ItemWithoutCustomerDecissionProps {
  readonly checkoutId: string;
  readonly checkoutItem: CheckoutItemWithoutCustomerDecission;
  readonly bookedProductsVariants: BookedProductsVariantsProjection;
  readonly currentProductVariant: ProductVariantProjection;
  readonly onReturn: () => void;
}

const { space6 } = theme();

const ItemWithoutCustomerDecission: FC<ItemWithoutCustomerDecissionProps> = ({
  checkoutId,
  checkoutItem,
  bookedProductsVariants,
  currentProductVariant,
  onReturn,
}) => {
  const logger = useLogger();
  const {
    customer: { country, segment },
  } = useStaticInfo();

  /* KeepCheckoutItem */
  const [keepCheckoutItem, keepCheckoutItemStatus] = useKeepCheckoutItem({ checkoutItemId: checkoutItem.id, logger });
  const trackKeepItem = useTrackKeepItem({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId,
    checkoutItemId: checkoutItem.id,
  });
  const handleOnKeep = useCallback(() => {
    keepCheckoutItem();
    trackKeepItem();
  }, [keepCheckoutItem, trackKeepItem]);
  /* KeepCheckoutItem */

  /* ReplaceCheckoutItem */
  const [replaceCheckoutItem, replaceCheckoutItemStatus] = useReplaceCheckoutItem({
    checkoutItemId: checkoutItem.id,
    logger,
  });
  const trackReplaceItem = useTrackReplaceItem({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId,
    checkoutItemId: checkoutItem.id,
    productVariantId: checkoutItem.productVariant.id,
  });
  const handleOnReplace = useCallback(
    (replacedForId: string) => {
      replaceCheckoutItem({ replacedForId });
      trackReplaceItem({ replaceFor: replacedForId });
    },
    [replaceCheckoutItem, trackReplaceItem],
  );
  /* ReplaceCheckoutItem */

  const [sizeWithoutStockModalVisible, setSizeWithoutStockModalVisible] = useState(false);
  const handleOnShowSizeWithoutStockModal = useCallback(() => setSizeWithoutStockModalVisible(true), []);
  const handleOnHideSizeWithoutStockModal = useCallback(() => setSizeWithoutStockModalVisible(false), []);

  const [stickyHeight, setStickyHeight] = useState<number>(0);
  const handleOnStickyLayout = useCallback(({ height }: LayoutRectangle) => setStickyHeight(height), []);

  const dependenciesLoaded =
    keepCheckoutItemStatus !== CommandStatus.LOADING && replaceCheckoutItemStatus !== CommandStatus.LOADING;

  if (!dependenciesLoaded) {
    return <Spinner testID="spinner" />;
  }

  return (
    <>
      <SizeWithoutStockModal visible={sizeWithoutStockModalVisible} onDismiss={handleOnHideSizeWithoutStockModal} />

      <ScrollView showsVerticalScrollIndicator={false}>
        <Body style={{ row: style.container }}>
          <ProductVariant
            checkoutId={checkoutId}
            checkoutItem={checkoutItem}
            country={country}
            currentProductVariant={currentProductVariant}
            segment={segment}
            style={{ content: { paddingBottom: Platform.OS === "web" ? space6 : stickyHeight } }}
          />
        </Body>
      </ScrollView>

      <ItemActions
        country={country}
        currentProductVariant={currentProductVariant}
        productVariants={bookedProductsVariants?.productVariants}
        onKeep={handleOnKeep}
        onLayout={handleOnStickyLayout}
        onReplace={handleOnReplace}
        onReturn={onReturn}
        onShowSizeWithoutStockModal={handleOnShowSizeWithoutStockModal}
      />
    </>
  );
};

export type { CheckoutItemWithoutCustomerDecission };
export { ItemWithoutCustomerDecission };
