import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space10 } = theme();

const style = StyleSheet.create({
  container: {
    paddingBottom: space10,
    paddingHorizontal: 0,
  },
});

export { style };
