import { useCallback, useEffect } from "react";
import { useEvent } from "@lookiero/event";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";
import { I18nMessages } from "../i18n/i18n";

const PAYMENT_ERROR = "ERROR";
const PAYMENT_SUCCESS = "PAYMENT_INSTRUMENT_UPDATED";

interface Message {
  readonly id: string;
}
interface OnSuccessFunctionArgs {
  readonly message: Message;
}
interface OnSuccessFunction {
  (args: OnSuccessFunctionArgs): void;
}

interface Toaster {
  readonly id: string;
}
interface Error {
  readonly toaster?: Toaster;
}
interface OnErrorFunctionArgs {
  readonly error: Error;
}
interface OnErrorFunction {
  (args: OnErrorFunctionArgs): void;
}

interface UsePaymentInstrumentEventsFunctionArgs {
  readonly logger: Logger;
}

interface UsePaymentInstrumentEventsFunction {
  (args: UsePaymentInstrumentEventsFunctionArgs): void;
}

const usePaymentInstrumentEvents: UsePaymentInstrumentEventsFunction = ({ logger }) => {
  const { subscribe, unsubscribe } = useEvent();

  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const onSuccess: OnSuccessFunction = useCallback(
    ({ message }) => createNotification({ bodyI18nKey: message.id, level: NotificationLevel.SUCCESS }),
    [createNotification],
  );

  const onError: OnErrorFunction = useCallback(
    ({ error }) => {
      if (error.toaster) {
        createNotification({
          bodyI18nKey: error.toaster.id || I18nMessages.CHECKOUT_TOAST_PAYMENT_ERROR,
          level: NotificationLevel.ERROR,
        });
      }
    },
    [createNotification],
  );

  useEffect(() => {
    subscribe({ event: PAYMENT_ERROR }, onError);
    subscribe({ event: PAYMENT_SUCCESS }, onSuccess);

    return () => {
      unsubscribe({ event: PAYMENT_ERROR }, onError);
      unsubscribe({ event: PAYMENT_SUCCESS }, onSuccess);
    };
  }, [subscribe, unsubscribe, createNotification, onError, onSuccess]);
};

export { usePaymentInstrumentEvents };
