import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space1, space6, colorTextMedium } = theme();

const style = StyleSheet.create({
  description: {
    color: colorTextMedium,
    marginBottom: space6,
    width: "100%",
  },
  modalContent: {
    paddingHorizontal: space6,
  },
  title: {
    marginBottom: space1,
    width: "100%",
  },
});
export { style };
