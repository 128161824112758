import React from "react";
import { Text, View } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages } from "../../../../../i18n/i18n";
import { ReturnQuestionItem, ReturnQuestionItemProps } from "../ReturnQuestionItem";
import { style } from "./HostDefaultReturnQuestionItem.style";

const HostDefaultReturnQuestionItem: ReturnQuestionItem = ({ returnQuestion, children }: ReturnQuestionItemProps) => {
  const titleText = useI18nMessage({ id: returnQuestion.name });
  const isAllOptions = returnQuestion.name === I18nMessages.RETURN_QUESTION_MAIN_ALL_OPINION;

  return (
    <>
      {titleText && titleText !== " " && !isAllOptions ? (
        <View style={style.title}>
          <Text level={3} action>
            {titleText}
          </Text>
        </View>
      ) : null}
      {children}
    </>
  );
};

export { HostDefaultReturnQuestionItem };
