import React, { FC } from "react";
import { ReturnQuestionProjection } from "../../../../../projection/returnQuestion/returnQuestion";
import ReturnQuestion from "./ReturnQuestion";

interface ReturnQuestionsProps {
  readonly returnQuestions: ReturnQuestionProjection[];
  readonly portalHostName?: string;
}

const ReturnQuestions: FC<ReturnQuestionsProps> = ({ returnQuestions, portalHostName }) => (
  <>
    {returnQuestions.map((returnQuestion: ReturnQuestionProjection) => (
      <ReturnQuestion
        key={returnQuestion.id}
        portalHostName={portalHostName}
        returnQuestion={returnQuestion}
        returnQuestionParent={{} as ReturnQuestionProjection}
      />
    ))}
  </>
);

export { ReturnQuestions };
