import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage } from "./tracking";
import { ChangeFeedbackTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface ChangeFeedbackFunctionArgs {
  readonly questionId: string;
  readonly questionName: string;
  readonly feedback: string | undefined;
}
interface ChangeFeedbackFunction {
  (args: ChangeFeedbackFunctionArgs): void;
}

interface UseTrackChangeFeedbackArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly checkoutId?: string;
  readonly segment: Segment;
}
interface UseTrackChangeFeedback {
  (agrs: UseTrackChangeFeedbackArgs): ChangeFeedbackFunction;
}

const useTrackChangeFeedback: UseTrackChangeFeedback = ({ page, country, checkoutId, segment }) => {
  const emitUserEvent = useEmitUserEvent<ChangeFeedbackTrackingEvent>();

  const changeFeedback: ChangeFeedbackFunction = useCallback(
    ({ questionId, questionName, feedback }) => {
      if (!checkoutId) {
        return;
      }

      const changeFeedbackTrackingEvent: ChangeFeedbackTrackingEvent = {
        event: TrackingEventName.CHANGE_FEEDBACK,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        questionId,
        questionName,
        feedback,
      };

      emitUserEvent(changeFeedbackTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, page, segment],
  );

  return changeFeedback;
};

export { useTrackChangeFeedback };
