import React, { FC } from "react";
import { CheckoutQuestionProjection } from "../../../../../projection/checkoutQuestion/checkoutQuestion";
import CheckoutQuestion from "./CheckoutQuestion";

interface CheckoutQuestionsProps {
  readonly checkoutQuestions: CheckoutQuestionProjection[];
}

const CheckoutQuestions: FC<CheckoutQuestionsProps> = ({ checkoutQuestions }) => (
  <>
    {checkoutQuestions.map((checkoutQuestion: CheckoutQuestionProjection) => (
      <CheckoutQuestion key={checkoutQuestion.id} checkoutQuestion={checkoutQuestion} checkoutQuestionParentId="" />
    ))}
  </>
);

export { CheckoutQuestions };
