import { HttpPostFunction } from "@lookiero/sty-psp-http";
import {
  PaymentFlowPayloadByCheckoutIdView,
  ViewPaymentFlowPayloadByCheckoutIdResult,
} from "../../../projection/payment/viewPaymentFlowPayloadByCheckoutId";

interface HttpPaymentFlowPayloadByCheckoutIdView extends PaymentFlowPayloadByCheckoutIdView {}

interface HttpPaymentFlowPayloadByCheckoutIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpPaymentFlowPayloadByCheckoutIdViewFunction {
  (args: HttpPaymentFlowPayloadByCheckoutIdViewFunctionArgs): HttpPaymentFlowPayloadByCheckoutIdView;
}

interface ViewPaymentFlowPayloadByCheckoutIdResponse {
  readonly result: ViewPaymentFlowPayloadByCheckoutIdResult;
}

const httpPaymentFlowPayloadByCheckoutIdView: HttpPaymentFlowPayloadByCheckoutIdViewFunction =
  ({ httpPost }) =>
  async ({ checkoutId, signal }) =>
    await httpPost<ViewPaymentFlowPayloadByCheckoutIdResponse, ViewPaymentFlowPayloadByCheckoutIdResult>({
      endpoint: "/view-payment-flow-payload-by-checkout-id",
      body: { checkoutId },
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpPaymentFlowPayloadByCheckoutIdView };
