import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const {
  colorBgSurface,
  borderRadius3,
  borderRadiusFull,
  borderWidth2,
  colorBorderInput,
  colorBgPrimaryLight,
  space05,
  space1,
  space2,
  space4,
} = theme();

const IMAGE_WIDTH = 104;
const IMAGE_HEIGHT = 132;

const style = StyleSheet.create({
  container: {
    alignItems: "flex-end",
    borderBottomColor: colorBgPrimaryLight,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: space4,
  },
  descriptionContainer: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    marginLeft: space2,
  },
  infoProductVariant: {
    alignItems: "flex-start",
  },
  media: {
    borderColor: colorBorderInput,
    borderRadius: borderRadius3,
    borderWidth: borderWidth2,
    height: IMAGE_HEIGHT,
    width: IMAGE_WIDTH,
  },
  row: {
    alignItems: "flex-end",
    flexDirection: "row",
  },
  sizeChange: {
    backgroundColor: colorBgSurface,
    borderRadius: borderRadiusFull,
    marginTop: space4,
    paddingBottom: space05,
    paddingHorizontal: space2,
    paddingTop: space1,
  },
});

export { style, IMAGE_WIDTH };
