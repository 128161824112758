import React, { createContext, FC, ReactNode, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { CheckoutQuestionType } from "../../../../../../projection/checkoutQuestion/checkoutQuestion";
import { CheckoutQuestionItem } from "../components/CheckoutQuestionItem";

type CheckoutQuestionItems = Record<CheckoutQuestionType, CheckoutQuestionItem>;

const CheckoutQuestionItemContext = createContext<CheckoutQuestionItems>({} as CheckoutQuestionItems);

interface CheckoutQuestionItemContextProviderProps {
  readonly checkoutQuestionItems: CheckoutQuestionItems;
  readonly children: ReactNode;
}

const CheckoutQuestionItemProvider: FC<CheckoutQuestionItemContextProviderProps> = ({
  checkoutQuestionItems,
  children,
}) => (
  <CheckoutQuestionItemContext.Provider value={checkoutQuestionItems}>{children}</CheckoutQuestionItemContext.Provider>
);

interface UseCheckoutQuestionItemFunctionArgs {
  readonly type: CheckoutQuestionType;
}

interface UseCheckoutQuestionItemFunction {
  (args: UseCheckoutQuestionItemFunctionArgs): CheckoutQuestionItem;
}

const useCheckoutQuestionItem: UseCheckoutQuestionItemFunction = ({ type }) => {
  const items = useContext<CheckoutQuestionItems>(CheckoutQuestionItemContext);
  const item = useMemo(() => items[type], [items, type]);

  invariant(
    items,
    "Your are trying to use the useCheckoutQuestionItem hook without wrapping your app with the <CheckoutQuestionItemProvider>.",
  );

  invariant(item, `The provided CheckoutQuestionType (${type}) is NOT SUPPORTED`);

  return item;
};

export type { CheckoutQuestionItems };
export { useCheckoutQuestionItem, CheckoutQuestionItemProvider };
