enum CheckoutQuestionType {
  HOST_DEFAULT = "HOST_DEFAULT",
  HOST_TEXTAREA = "HOST_TEXTAREA",
  HOST_SELECT = "HOST_SELECT",
  TEXTAREA = "TEXTAREA",
  ICON = "ICON",
  BUTTON = "BUTTON",
}

interface CheckoutQuestionProjection {
  readonly id: string;
  readonly name: string;
  readonly placeholder: string; // required for CheckoutQuestionType.SELECT | CheckoutQuestionType.TEXTAREA
  readonly type: CheckoutQuestionType;
  readonly children?: CheckoutQuestionProjection[];
  readonly showCondition: string[];
}

export { CheckoutQuestionType };
export type { CheckoutQuestionProjection };
