import { StyleSheet, Platform } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
import { HEADER_HEIGHT } from "../../components/templates/header/Header.style";

const { borderRadius4, colorBgPrimaryLight, colorBgBase, space2, space3, space4, space6, space12, space16 } = theme();

const style = StyleSheet.create({
  contentWrapper: {
    backgroundColor: colorBgBase,
    flexGrow: 0,
    paddingHorizontal: space6,
    paddingVertical: space6,
  },
  desktopContentWrapper: {
    borderRadius: borderRadius4,
    paddingVertical: space12,
  },
  desktopLayoutSpacing: {
    paddingVertical: space12,
  },
  desktopListSpacing: {
    paddingRight: space16,
  },
  desktopResume: {
    borderRadius: borderRadius4,
  },
  header: {
    height: HEADER_HEIGHT,
  },
  paymentSelector: {
    marginTop: space6,
  },
  princingWrapper: {
    padding: space6,
  },
  princingWrapperSmall: {
    backgroundColor: colorBgPrimaryLight,
    paddingBottom: space2,
  },
  resume: {
    overflow: "hidden",
    backgroundColor: colorBgBase,
    ...Platform.select({
      web: {
        position: "sticky",
        top: space12,
        alignSelf: "flex-start",
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any,
  scrollView: {
    backgroundColor: colorBgPrimaryLight,
    flex: 1,
  },
  sticky: {
    paddingBottom: space4,
    paddingTop: space3,
  },
  title: {
    marginBottom: space4,
  },
});

export { style };
