import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space3, space6, space8 } = theme();

const style = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    paddingHorizontal: space6,
    paddingVertical: space8,
  },
  containerDesktop: {
    paddingHorizontal: space3,
  },
});

export { style };
