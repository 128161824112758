import { FeedbackProjection } from "../../../../../../projection/feedback/feedback";
import { ReturnQuestionProjection } from "../../../../../../projection/returnQuestion/returnQuestion";

type Uuid = string;

const REGEX =
  /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
const isUuid = (value: string): value is Uuid => {
  return typeof value === "string" && REGEX.test(value);
};

interface RecursiveFeedbackForReturnQuestionFunctionArgs {
  readonly feedback: FeedbackProjection;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly translate: (i18nString: string) => string;
  readonly acc?: string[];
}

interface RecursiveFeedbackForReturnQuestionFunction {
  (args: RecursiveFeedbackForReturnQuestionFunctionArgs): string[];
}

const feedbackForReturnQuestion: RecursiveFeedbackForReturnQuestionFunction = ({
  feedback,
  returnQuestion,
  translate,
  acc = [],
}) => {
  const returnQuestionId = Object.keys(feedback).find((id) => id === returnQuestion.id);

  if (returnQuestionId) {
    const returnQuestionFeedback = feedback[returnQuestionId];

    if (isUuid(returnQuestionFeedback as string)) {
      const feebackReturnQuestionChild = returnQuestion.children?.find(
        (childReturnQuestion) => childReturnQuestion.id === returnQuestionFeedback,
      );

      if (feebackReturnQuestionChild) {
        return [
          ...acc,
          translate(feebackReturnQuestionChild?.name),
          ...feedbackForReturnQuestion({ feedback, returnQuestion: feebackReturnQuestionChild, translate, acc }),
        ];
      }

      return acc;
    }

    return [...acc, returnQuestionFeedback as string];
  }

  return (
    returnQuestion.children?.reduce(
      (acc, childReturnQuestion) => [
        ...acc,
        ...feedbackForReturnQuestion({ feedback, returnQuestion: childReturnQuestion, translate, acc }),
      ],
      [] as string[],
    ) || []
  );
};

interface RecursiveDeepestReturnQuestionWithFeedbackFunctionArgs {
  readonly feedback: FeedbackProjection;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly deepestReturnQuestion?: ReturnQuestionProjection;
}

interface RecursiveDeepestReturnQuestionWithFeedbackFunction {
  (
    args: RecursiveDeepestReturnQuestionWithFeedbackFunctionArgs,
  ): [question: ReturnQuestionProjection | undefined, isLeaf: boolean];
}

const deepestReturnQuestionWithFeedbackForReturnQuestion: RecursiveDeepestReturnQuestionWithFeedbackFunction = ({
  feedback,
  returnQuestion,
  deepestReturnQuestion,
}) => {
  const returnQuestionId = Object.keys(feedback).find((id) => id === returnQuestion.id);

  if (returnQuestionId) {
    const returnQuestionFeedback = feedback[returnQuestionId];

    if (isUuid(returnQuestionFeedback as string)) {
      const feebackReturnQuestionChild = returnQuestion.children?.find(
        (childReturnQuestion) => childReturnQuestion.id === returnQuestionFeedback,
      );

      if (feebackReturnQuestionChild) {
        return deepestReturnQuestionWithFeedbackForReturnQuestion({
          feedback,
          returnQuestion: feebackReturnQuestionChild,
          deepestReturnQuestion: returnQuestion,
        });
      }

      return [returnQuestion, false];
    }

    return [returnQuestion, false];
  }

  const feebackReturnQuestionChild = returnQuestion.children?.find((childReturnQuestion) =>
    Object.keys(feedback).find((id) => id === childReturnQuestion.id),
  );

  if (feebackReturnQuestionChild) {
    return deepestReturnQuestionWithFeedbackForReturnQuestion({
      feedback,
      returnQuestion: feebackReturnQuestionChild,
      deepestReturnQuestion: feebackReturnQuestionChild,
    });
  }

  return [deepestReturnQuestion, !returnQuestion.children || returnQuestion.children.length === 0];
};

export { feedbackForReturnQuestion, deepestReturnQuestionWithFeedbackForReturnQuestion };
