import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space4 } = theme();

const style = StyleSheet.create({
  titleContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: space4,
  },
});

export { style };
