import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorTextError, colorTextMedium, space2 } = theme();

const style = StyleSheet.create({
  discountedPercentage: {
    color: colorTextError,
  },
  discountedPriceInfo: {
    alignItems: "flex-end",
    marginLeft: space2,
  },
  discountedPriceText: {
    color: colorTextMedium,
  },
  price: {
    flexDirection: "row",
  },
});

export { style };
