import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space1, space3 } = theme();

const style = StyleSheet.create({
  collapsed: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  collapsedContent: {
    flex: 1,
    marginBottom: space1,
  },
  iconContainer: {
    alignItems: "center",
    marginBottom: space3,
  },
  totalCollapsed: {
    marginBottom: space1,
  },
});

export { style };
