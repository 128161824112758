import { HttpGetFunction } from "@lookiero/sty-psp-http";
import {
  IsSizeChangeEnabledByCheckoutIdProjection,
  IsSizeChangeEnabledByCheckoutIdView,
} from "../../../projection/checkout/viewIsSizeChangeEnabledByCheckoutId";

interface HttpIsSizeChangeEnabledByCheckoutIdView extends IsSizeChangeEnabledByCheckoutIdView {}

interface HttpIsSizeChangeEnabledByCheckoutIdViewFunctionArgs {
  readonly httpGet: HttpGetFunction;
}

interface HttpIsSizeChangeEnabledByCheckoutIdViewFunction {
  (args: HttpIsSizeChangeEnabledByCheckoutIdViewFunctionArgs): HttpIsSizeChangeEnabledByCheckoutIdView;
}

const httpIsSizeChangeEnabledByCheckoutIdView: HttpIsSizeChangeEnabledByCheckoutIdViewFunction =
  ({ httpGet }) =>
  async ({ checkoutId, signal }) =>
    await httpGet<IsSizeChangeEnabledByCheckoutIdProjection>({
      endpoint: `/is-size-change-enabled-by-checkout-id/${checkoutId}`,
      signal,
      result: {
        error: false,
        success: (response) => response,
      },
    });

export { httpIsSizeChangeEnabledByCheckoutIdView };
