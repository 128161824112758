import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space6, space12, colorBgBase, borderRadius4 } = theme();

const style = StyleSheet.create({
  container: {
    backgroundColor: colorBgBase,
  },
  content: {
    paddingVertical: space6,
  },
  largeContainer: {
    borderRadius: borderRadius4,
    marginTop: space12,
    padding: space12,
    paddingBottom: 0,
  },
});

export { style };
