import { useCallback } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { startCheckout } from "../../../../domain/checkout/command/startCheckout";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface StartCheckoutFunction {
  (): Promise<void> | undefined;
}

interface UseStartCheckoutArgs {
  readonly checkoutId?: string;
  readonly logger: Logger;
}

type UseStartCheckoutResult = [start: StartCheckoutFunction, status: CommandStatus];

interface UseStartCheckout {
  (args: UseStartCheckoutArgs): UseStartCheckoutResult;
}

const useStartCheckout: UseStartCheckout = ({ checkoutId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const start = useCallback(async () => {
    invariant(checkoutId, "CheckoutId must be defined in order to start checkout");

    try {
      await commandBus(startCheckout({ aggregateId: checkoutId }));
    } catch (error) {
      if (!(error as Error)?.message?.includes("Invariant failed")) {
        logger.captureException(error as Error);
      }
    }
  }, [checkoutId, commandBus, logger]);

  return [start, status];
};

export { useStartCheckout };
