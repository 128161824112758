import React, { FC, useCallback, useEffect, useRef } from "react";
import { Spinner } from "@lookiero/aurora";
import { CommandStatus, QueryStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { Layout as UiLayout, useScreenSize } from "@lookiero/sty-psp-ui";
import { CheckoutFeedbackProjection } from "../../../../projection/checkoutFeedback/checkoutFeedback";
import { CheckoutQuestionType } from "../../../../projection/checkoutQuestion/checkoutQuestion";
import { useGiveCheckoutFeedback } from "../../../domain/checkoutFeedback/react/useGiveCheckoutFeedback";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { useListCheckoutQuestionsByCheckoutId } from "../../../projection/checkoutQuestion/react/useListCheckoutQuestionsByCheckoutId";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackChangeFeedback } from "../../../tracking/useTrackChangeFeedback";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { useTrackPressContinue } from "../../../tracking/useTrackPressContinue";
import { Body } from "../../components/layouts/body/Body";
import { CheckoutQuestionFeedbackProvider } from "../../components/organisms/checkoutQuestions/behaviors/useCheckoutQuestionFeedback";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { style } from "./Feedback.style";
import { CheckoutQuestionsForm } from "./components/checkoutQuestionsForm/CheckoutQuestionsForm";

interface HandleOnChangedFeedbackFunctionArgs {
  readonly checkoutQuestionId: string;
  readonly checkoutQuestionFeedback: string | undefined; // uuid | free-text
  readonly hasFeedback?: boolean;
}
interface HandleOnChangedFeedbackFunction {
  (args: HandleOnChangedFeedbackFunctionArgs): void;
}

interface FeedbackProps {
  readonly layout: UiLayout;
}

const Feedback: FC<FeedbackProps> = ({ layout: Layout }) => {
  const logger = useLogger();
  const screenSize = useScreenSize();
  const isDesktop = screenSize === "L";
  const {
    customer: { customerId, country, segment },
  } = useStaticInfo();
  const [checkout, checkoutStatus] = useViewFirstAvailableCheckoutByCustomerId({ customerId });
  const [checkoutQuestions, checkoutQuestionsStatus] = useListCheckoutQuestionsByCheckoutId({
    checkoutId: checkout?.id as string,
  });

  useTrackPageView({
    page: TrackingPage.FEEDBACK,
    country,
    segment,
    checkoutId: checkout?.id,
  });

  const trackChangeFeedback = useTrackChangeFeedback({
    page: TrackingPage.FEEDBACK,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnChangedFeedback: HandleOnChangedFeedbackFunction = useCallback(
    ({ checkoutQuestionId, checkoutQuestionFeedback, hasFeedback }) => {
      const question = checkoutQuestions?.find((question) => question.id === checkoutQuestionId);

      if (question?.type === CheckoutQuestionType.HOST_TEXTAREA && hasFeedback) {
        return;
      }

      trackChangeFeedback({
        questionId: checkoutQuestionId,
        questionName: question?.name as string,
        feedback: checkoutQuestionFeedback,
      });
    },
    [checkoutQuestions, trackChangeFeedback],
  );

  const [giveCheckoutFeedback, giveCheckoutFeedbackStatus] = useGiveCheckoutFeedback({
    checkoutId: checkout?.id as string,
    logger,
  });
  const checkoutFeedbackGivenRef = useRef(false);
  const trackPressContinue = useTrackPressContinue({
    page: TrackingPage.FEEDBACK,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnSubmit = useCallback(
    async (feedbacks: CheckoutFeedbackProjection) => {
      try {
        trackPressContinue();
        await giveCheckoutFeedback({ feedbacks });
        checkoutFeedbackGivenRef.current = true;
      } catch (e) {}
    },
    [giveCheckoutFeedback, trackPressContinue],
  );

  /**
   * Give empty feedback when this view is unmounted
   * (if feedback has not already been given)
   */
  useEffect(
    () => () => {
      if (!checkoutFeedbackGivenRef.current) {
        giveCheckoutFeedback({ feedbacks: {} });
      }
    },
    [giveCheckoutFeedback],
  );

  const dependenciesLoadedStatuses = [QueryStatus.ERROR, QueryStatus.SUCCESS];
  const dependenciesLoaded =
    dependenciesLoadedStatuses.includes(checkoutStatus) && dependenciesLoadedStatuses.includes(checkoutQuestionsStatus);

  if (!dependenciesLoaded) return <Spinner />;

  return (
    <CheckoutQuestionFeedbackProvider feedback={{}} onChanged={handleOnChangedFeedback}>
      <Layout>
        <Body style={{ row: [style.container, isDesktop && style.containerDesktop] }}>
          <CheckoutQuestionsForm
            checkoutQuestions={checkoutQuestions || []}
            submitButtonDisabled={giveCheckoutFeedbackStatus === CommandStatus.LOADING}
            onSubmit={handleOnSubmit}
          />
        </Body>
      </Layout>
    </CheckoutQuestionFeedbackProvider>
  );
};

export { Feedback };
