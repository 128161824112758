enum ReturnQuestionType {
  HOST_DEFAULT = "HOST_DEFAULT",
  HOST_TEXTAREA = "HOST_TEXTAREA",
  HOST_SELECT = "HOST_SELECT",
  HOST_STACK = "HOST_STACK",
  TEXTAREA = "TEXTAREA",
  OPTION = "OPTION",
  // ICON = "ICON",
}

interface ReturnQuestionProjection {
  readonly id: string;
  readonly name: string;
  readonly placeholder: string; // required for ReturnQuestionType.SELECT | ReturnQuestionType.TEXTAREA
  readonly type: ReturnQuestionType;
  readonly children?: ReturnQuestionProjection[];
}

export { ReturnQuestionType };
export type { ReturnQuestionProjection };
