import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, ReturnItemTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface ReturnItemFunction {
  (): void;
}

interface UseTrackReturnItemFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
  readonly checkoutItemId: string;
}
interface UseTrackReturnItemFunction {
  (agrs: UseTrackReturnItemFunctionArgs): ReturnItemFunction;
}

const useTrackReturnItem: UseTrackReturnItemFunction = ({ page, country, segment, checkoutId, checkoutItemId }) => {
  const emitUserEvent = useEmitUserEvent<ReturnItemTrackingEvent>();

  const returnItem: ReturnItemFunction = useCallback(() => {
    if (!checkoutId) {
      return;
    }

    const returnItemTrackingEvent: ReturnItemTrackingEvent = {
      event: TrackingEventName.RETURN_ITEM,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${page}`,
      store: country,
      segment,
      checkoutId,
      checkoutItemId,
    };

    emitUserEvent(returnItemTrackingEvent);
  }, [checkoutId, checkoutItemId, country, emitUserEvent, page, segment]);

  return returnItem;
};

export { useTrackReturnItem };
