import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { keepCheckoutItem } from "../../../../domain/checkoutItem/command/keepCheckoutItem";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface KeepCheckoutItemFunction {
  (): Promise<void>;
}

interface UseKeepCheckoutItemFunctionArgs {
  readonly checkoutItemId: string;
  readonly logger: Logger;
}

type UseKeepCheckoutItemResult = [keep: KeepCheckoutItemFunction, status: CommandStatus];

interface UseKeepCheckoutItemFunction {
  (args: UseKeepCheckoutItemFunctionArgs): UseKeepCheckoutItemResult;
}

const useKeepCheckoutItem: UseKeepCheckoutItemFunction = ({ checkoutItemId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const keep = useCallback(async () => {
    try {
      await commandBus(keepCheckoutItem({ aggregateId: checkoutItemId }));
    } catch (error) {
      logger.captureException(error as Error);
      createNotification({
        level: NotificationLevel.ERROR,
        bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
      });
    }
  }, [checkoutItemId, commandBus, createNotification, logger]);

  return [keep, status];
};

export { useKeepCheckoutItem };
