import React from "react";
import { ALIGN, InfoBox, INFOBOX_TYPE, useDevice } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages } from "../../../../i18n/i18n";

const DeliveryBanner = () => {
  const { screen } = useDevice();

  return (
    <InfoBox
      contentAlign={screen.L ? ALIGN.CENTER : undefined}
      testID="delivery-banner"
      text={useI18nMessage({ id: I18nMessages.CHECKOUT_DELIVERY_BANNER })}
      type={INFOBOX_TYPE.SQUARED}
    />
  );
};

export { DeliveryBanner };
