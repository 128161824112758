import { HttpPostFunction } from "@lookiero/sty-psp-http";
import {
  FirstAvailableCheckoutByCustomerIdView,
  ViewFirstAvailableCheckoutByCustomerIdResult,
} from "../../../projection/checkout/viewFirstAvailableCheckoutByCustomerId";
import { CheckoutDto, toCheckoutProjection } from "./checkout";

interface HttpFirstAvailableCheckoutByCustomerIdView extends FirstAvailableCheckoutByCustomerIdView {}

interface HttpFirstAvailableCheckoutByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpFirstAvailableCheckoutByCustomerIdViewFunction {
  (args: HttpFirstAvailableCheckoutByCustomerIdViewFunctionArgs): HttpFirstAvailableCheckoutByCustomerIdView;
}

interface ViewFirstAvailableCheckoutByCustomerIdResponse {
  readonly result: CheckoutDto;
}

const httpFirstAvailableCheckoutByCustomerIdView: HttpFirstAvailableCheckoutByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<ViewFirstAvailableCheckoutByCustomerIdResponse, ViewFirstAvailableCheckoutByCustomerIdResult>({
      endpoint: "/view-first-available-checkout-by-customer-id",
      body: { customerId },
      signal,
      result: {
        error: null,
        success: (response) => toCheckoutProjection(response.result),
      },
    });

export { httpFirstAvailableCheckoutByCustomerIdView };
