import React, { FC } from "react";
import { View } from "react-native";
import { ButtonIcon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import {
  ReturnQuestionProjection,
  ReturnQuestionType,
} from "../../../../../../projection/returnQuestion/returnQuestion";
import { ReturnQuestions } from "../../../../components/organisms/returnQuestions/ReturnQuestions";
import {
  ReturnQuestionItemProvider,
  ReturnQuestionItems,
} from "../../../../components/organisms/returnQuestions/behaviors/useReturnQuestionItem";
import { HostDefaultReturnQuestionFeedbackItem } from "../../../../components/organisms/returnQuestions/components/hostDefaultReturnQuestionFeedbackItem/HostDefaultReturnQuestionFeedbackItem";
import { ReturnQuestionFeedbackItem } from "../../../../components/organisms/returnQuestions/components/returnQuestionFeedbackItem/ReturnQuestionFeedbackItem";
import { I18nMessages } from "../../../../i18n/i18n";
import { style } from "./ReturnQuestionsFeedback.style";

const returnQuestionItems: ReturnQuestionItems = {
  [ReturnQuestionType.HOST_DEFAULT]: HostDefaultReturnQuestionFeedbackItem,
  [ReturnQuestionType.HOST_TEXTAREA]: ReturnQuestionFeedbackItem,
  [ReturnQuestionType.HOST_SELECT]: ReturnQuestionFeedbackItem,
  [ReturnQuestionType.HOST_STACK]: ReturnQuestionFeedbackItem,
  [ReturnQuestionType.TEXTAREA]: ReturnQuestionFeedbackItem,
  [ReturnQuestionType.OPTION]: ReturnQuestionFeedbackItem,
};

interface ReturnQuestionsFeedbackProps {
  readonly returnQuestions: ReturnQuestionProjection[];
  readonly onEditFeedback: () => void;
}

const ReturnQuestionsFeedback: FC<ReturnQuestionsFeedbackProps> = ({ returnQuestions, onEditFeedback }) => {
  const titleText = useI18nMessage({ id: I18nMessages.FEEDBACK_TITLE });

  return (
    <View>
      <View style={style.titleContainer}>
        <Text level={2}>{titleText}</Text>
        <ButtonIcon name="pencil" testID="edit-feedback" onPress={onEditFeedback} />
      </View>

      <ReturnQuestionItemProvider returnQuestionItems={returnQuestionItems}>
        <ReturnQuestions returnQuestions={returnQuestions} />
      </ReturnQuestionItemProvider>
    </View>
  );
};
export { ReturnQuestionsFeedback };
