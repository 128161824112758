import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space4, space6 } = theme();

const style = StyleSheet.create({
  brand: {
    flex: 1,
  },
  container: {
    width: "100%",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    marginBottom: space4,
  },
  smallContainer: {
    paddingHorizontal: space6,
  },
});

export { style };
