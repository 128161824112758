import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { CheckoutByIdView, ViewCheckoutByIdResult } from "../../../projection/checkout/viewCheckoutById";
import { CheckoutDto, toCheckoutProjection } from "./checkout";

interface HttpCheckoutByIdView extends CheckoutByIdView {}

interface HttpCheckoutByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutByIdViewFunction {
  (args: HttpCheckoutByIdViewFunctionArgs): HttpCheckoutByIdView;
}

interface ViewCheckoutByIdResponse {
  readonly result: CheckoutDto;
}

const httpCheckoutByIdView: HttpCheckoutByIdViewFunction =
  ({ httpPost }) =>
  async ({ checkoutId, signal }) =>
    await httpPost<ViewCheckoutByIdResponse, ViewCheckoutByIdResult>({
      endpoint: "/view-checkout-by-id",
      body: { checkoutId },
      signal,
      result: {
        error: null,
        success: (response) => toCheckoutProjection(response.result),
      },
    });

export { httpCheckoutByIdView };
