import React from "react";
import { View } from "react-native";
import { COLOR, Text } from "@lookiero/aurora";
import { useIntl } from "@lookiero/i18n-react";
import { Modal } from "@lookiero/sty-psp-ui";
import { ReturnQuestionProjection } from "../../../../../../../projection/returnQuestion/returnQuestion";
import ReturnQuestion from "../../ReturnQuestion";
import { style } from "./ModalOptionReturnQuestionItems.style";

interface ModalOptionReturnQuestionItemsProps {
  readonly portalHostName?: string;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly returnQuestionParent: ReturnQuestionProjection;
  readonly visible: boolean;
  readonly onClose: () => void;
}

const ModalOptionReturnQuestionItems = ({
  portalHostName,
  returnQuestion,
  visible,
  onClose,
}: ModalOptionReturnQuestionItemsProps) => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: returnQuestion.name });

  return (
    <Modal portalHostName={portalHostName} visible={visible} scroll showCloseButton onClose={onClose}>
      <View style={style.modalContent}>
        <Text color={COLOR.TEXT_MEDIUM} level={2} style={style.optionText}>
          {title}
        </Text>
        <>
          {returnQuestion.children?.map((childReturnQuestion) => (
            <ReturnQuestion
              key={childReturnQuestion.id}
              portalHostName=""
              returnQuestion={childReturnQuestion}
              returnQuestionParent={returnQuestion}
            />
          ))}
        </>
      </View>
    </Modal>
  );
};

export { ModalOptionReturnQuestionItems };
