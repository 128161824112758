import React, { FC } from "react";
import Svg, { Circle, Path, SvgProps } from "react-native-svg";

const Sad: FC<SvgProps> = ({ strokeWidth = 1, ...props }) => (
  <Svg fill="none" height={48} width={48} {...props}>
    <Circle cx={24} cy={24} r={23} stroke="#000" strokeWidth={strokeWidth} />
    <Circle cx={16} cy={17} fill="#000" r={2} />
    <Circle cx={32} cy={17} fill="#000" r={2} />
    <Path d="M14 33.5s3-6 10-6 10 6 10 6" stroke="#000" strokeWidth={strokeWidth} />
  </Svg>
);

export { Sad };
