import React, { FC, useCallback, useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import { QueryStatus } from "@lookiero/messaging-react";
import { CheckoutItemStatus } from "../../../../domain/checkoutItem/model/checkoutItem";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { useTrackPressItem } from "../../../tracking/useTrackPressItem";
import { useTrackTabView } from "../../../tracking/useTrackTabView";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { Routes } from "../../routing/routes";
import { useBasePath } from "../../routing/useBasePath";
import { CheckoutItemsTabs } from "./components/checkoutItemsTabs/CheckoutItemsTabs";

type Tab = "keep" | "return";

const TAB_INDEX: Record<Tab, number> = {
  keep: 0,
  return: 1,
};

const SummaryTabs: FC = () => {
  const { tab } = useParams();
  const {
    customer: { customerId, country, segment },
  } = useStaticInfo();

  const tabIndex = useMemo(() => TAB_INDEX[tab as Tab] || 0, [tab]);

  const [checkout, checkoutStatus] = useViewFirstAvailableCheckoutByCustomerId({ customerId });

  const navigate = useNavigate();
  const basePath = useBasePath();

  useTrackPageView({
    page: TrackingPage.SUMMARY,
    country,
    segment,
    checkoutId: checkout?.id,
  });

  const trackPressItem = useTrackPressItem({
    page: TrackingPage.SUMMARY,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnPressItem = useCallback(
    (checkoutItemId: string) => {
      trackPressItem({ checkoutItemId });
      navigate(`${basePath}/${generatePath(Routes.ITEM_DETAIL, { id: checkoutItemId })}`);
    },
    [basePath, navigate, trackPressItem],
  );

  const trackTabView = useTrackTabView<Tab>({
    page: TrackingPage.SUMMARY,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnChangedTab = useCallback(
    (active: number) => trackTabView({ tab: active === 0 ? "keep" : "return" }),
    [trackTabView],
  );

  const checkoutItemsKept = useMemo(
    () =>
      checkout?.items.filter(
        (checkoutItem) =>
          checkoutItem.status === CheckoutItemStatus.KEPT || checkoutItem.status === CheckoutItemStatus.REPLACED,
      ),
    [checkout?.items],
  );
  const checkoutItemsReturned = useMemo(
    () =>
      checkout?.items.filter(
        (checkoutItem) =>
          checkoutItem.status === CheckoutItemStatus.RETURNED || checkoutItem.status === CheckoutItemStatus.REPLACED,
      ),
    [checkout?.items],
  );

  const dependenciesLoadedStatuses = [QueryStatus.ERROR, QueryStatus.SUCCESS];
  const dependenciesLoaded = dependenciesLoadedStatuses.includes(checkoutStatus);

  if (!dependenciesLoaded) {
    return <Spinner />;
  }

  return (
    <CheckoutItemsTabs
      checkoutItemsKept={checkoutItemsKept || []}
      checkoutItemsReturned={checkoutItemsReturned || []}
      country={country}
      tabIndex={tabIndex}
      onChanged={handleOnChangedTab}
      onPressItem={handleOnPressItem}
    />
  );
};

export { SummaryTabs };
