import { CheckoutItemStatus } from "../../domain/checkoutItem/model/checkoutItem";
import { FeedbackProjection } from "../feedback/feedback";
import { PriceProjection } from "../price/price";
import { SizeProjection } from "../size/size";

enum MediaPerspective {
  BACK = "BACK",
  BOTTOM_UP = "BOTTOM_UP",
  COLLAGE = "COLLAGE",
  DETAIL = "DETAIL",
  FLAT_BACK = "FLAT_BACK",
  FRONT = "FRONT",
  FRONT_FAR = "FRONT_FAR",
  LABEL = "LABEL",
  MAIN = "MAIN",
  MAIN_TRANSPARENT = "MAIN_TRANSPARENT",
  OTHER = "OTHER",
  SIDE = "SIDE",
}

interface MediaProjection {
  readonly id: string;
  readonly url: string;
  readonly perspective: MediaPerspective;
}

interface ColorProjection {
  readonly id: string;
  readonly label: string;
  readonly name: string;
}

interface CheckoutItemProductVariantProjection {
  readonly id: string;
  readonly media: MediaProjection[];
  readonly brand: string;
  readonly name: string;
  readonly size: SizeProjection;
  readonly color: ColorProjection;
}

interface CheckoutItemProjection {
  readonly id: string;
  readonly status: CheckoutItemStatus;
  readonly price: PriceProjection;
  readonly replacedFor: CheckoutItemProductVariantProjection | null;
  readonly productVariant: CheckoutItemProductVariantProjection;
  readonly feedbacks: FeedbackProjection;
}

export type { CheckoutItemProjection, CheckoutItemProductVariantProjection, ColorProjection, MediaProjection };
export { MediaPerspective };
