import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import {
  PROJECT,
  TrackingPage,
  PressContinueTrackingEvent,
  TrackingEventName,
  TrackingEventCategory,
} from "./tracking";

interface PressContinueFunction {
  (): void;
}

interface UseTrackPressContinueFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}
interface UseTrackPressContinueFunction {
  (agrs: UseTrackPressContinueFunctionArgs): PressContinueFunction;
}

const useTrackPressContinue: UseTrackPressContinueFunction = ({ page, country, segment, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<PressContinueTrackingEvent>();

  const pressContinue: PressContinueFunction = useCallback(() => {
    if (!checkoutId) {
      return;
    }

    const pressContinueTrackingEvent: PressContinueTrackingEvent = {
      event: TrackingEventName.PRESS_CONTINUE,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${page}`,
      store: country,
      segment,
      checkoutId,
    };

    emitUserEvent(pressContinueTrackingEvent);
  }, [checkoutId, country, emitUserEvent, page, segment]);

  return pressContinue;
};

export { useTrackPressContinue };
