import React, { FC, memo } from "react";
import { CheckoutQuestionProjection } from "../../../../../projection/checkoutQuestion/checkoutQuestion";
import { useCheckoutQuestionItem } from "./behaviors/useCheckoutQuestionItem";

interface CheckoutQuestionProps {
  readonly checkoutQuestionParentId: string;
  readonly checkoutQuestion: CheckoutQuestionProjection;
}

const CheckoutQuestion: FC<CheckoutQuestionProps> = ({
  checkoutQuestion,
  checkoutQuestionParentId,
}: CheckoutQuestionProps) => {
  const Item = useCheckoutQuestionItem({ type: checkoutQuestion.type });

  return (
    <Item
      checkoutQuestion={checkoutQuestion}
      checkoutQuestionParentId={checkoutQuestionParentId}
      testID={checkoutQuestion.id}
    >
      {checkoutQuestion.children?.map((childCheckoutQuestion) => (
        <CheckoutQuestion
          key={childCheckoutQuestion.id}
          checkoutQuestion={childCheckoutQuestion}
          checkoutQuestionParentId={checkoutQuestion.id}
        />
      ))}
    </Item>
  );
};

export default memo(CheckoutQuestion);
