import React, { FC, memo, Suspense } from "react";
import { Navigate, Outlet, useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import { I18n } from "@lookiero/i18n-react";
import { Kameleoon } from "@lookiero/sty-psp-ab-testing";
import { Locale } from "@lookiero/sty-psp-locale";
import { Layout } from "@lookiero/sty-psp-ui";
import { Customer } from "../../../projection/customer/customer";
import { Order } from "../../../projection/order/order";
import { Subscription } from "../../../projection/subscription/subscription";
import { KameleoonEnvironment } from "../../ab-testing/kameleoonEnvironment";
import { StaticInfoProvider } from "../hooks/useStaticInfo";
import { App } from "../views/App";
import { Checkout } from "../views/checkout/Checkout";
import { CheckoutPaymentModal } from "../views/checkout/components/checkoutPaymentModal/CheckoutPaymentModal";
import { Feedback } from "../views/feedback/Feedback";
import { Item } from "../views/item/Item";
import { Return } from "../views/return/Return";
import { Summary } from "../views/summary/Summary";
import { SummaryTabs } from "../views/summaryTabs/SummaryTabs";
import { CheckoutMiddleware } from "./CheckoutMiddleware";
import { Routes } from "./routes";
import { BasePathProvider } from "./useBasePath";

interface RoutingProps {
  readonly basePath?: string;
  readonly customer: Customer;
  readonly order: Order | undefined;
  readonly subscription: Subscription | undefined;
  readonly locale: Locale;
  readonly I18n: I18n;
  readonly kameleoon: KameleoonEnvironment;
  readonly layout: Layout;
  readonly getAuthToken: () => Promise<string>;
  readonly onNotAccessible: () => void;
  readonly onCheckoutSubmitted?: () => void;
  readonly onI18nError?: (err: Error) => void;
  readonly useRedirect: () => Record<string, string>;
  readonly useRoutes: typeof reactRouterUseRoutes;
}

const Routing: FC<RoutingProps> = ({
  basePath = "",
  customer,
  order,
  subscription,
  locale,
  I18n,
  kameleoon,
  layout,
  getAuthToken,
  onI18nError,
  onNotAccessible,
  onCheckoutSubmitted,
  useRedirect,
  useRoutes = reactRouterUseRoutes,
}) => {
  return useRoutes([
    {
      path: "",
      element: (
        <BasePathProvider basePath={basePath}>
          <StaticInfoProvider customer={customer} kameleoon={kameleoon}>
            <I18n loader={<Spinner />} locale={locale} onError={onI18nError}>
              <Kameleoon loader={<Spinner />} siteCode={kameleoon.siteCode}>
                <CheckoutMiddleware customerId={customer?.customerId as string} onNotAccessible={onNotAccessible}>
                  <App>
                    <Outlet />
                  </App>
                </CheckoutMiddleware>
              </Kameleoon>
            </I18n>
          </StaticInfoProvider>
        </BasePathProvider>
      ),
      children: [
        {
          path: Routes.ITEM,
          element: (
            <Suspense fallback={<Spinner />}>
              <Item layout={layout} />
            </Suspense>
          ),
        },
        {
          path: Routes.ITEM_DETAIL,
          element: (
            <Suspense fallback={<Spinner />}>
              <Item layout={layout} />
            </Suspense>
          ),
        },
        {
          path: Routes.SUMMARY,
          element: (
            <Suspense fallback={<Spinner />}>
              <Summary layout={layout}>
                <Outlet />
              </Summary>
            </Suspense>
          ),
          children: [
            {
              path: Routes.SUMMARY_TABS,
              element: (
                <Suspense fallback={<Spinner />}>
                  <SummaryTabs />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: Routes.CHECKOUT,
          element: (
            <Suspense fallback={<Spinner />}>
              <Checkout layout={layout} useRedirect={useRedirect}>
                <Outlet />
              </Checkout>
            </Suspense>
          ),
          children: [
            {
              path: Routes.CHECKOUT_PAYMENT,
              element: (
                <CheckoutPaymentModal
                  coupon={order?.coupon || null}
                  getAuthToken={getAuthToken}
                  isFirstOrder={order?.isFirstOrder as boolean}
                  orderNumber={order?.orderNumber as number}
                  subscription={subscription as Subscription}
                  onCheckoutSubmitted={onCheckoutSubmitted}
                />
              ),
            },
          ],
        },
        {
          path: Routes.FEEDBACK,
          element: (
            <Suspense fallback={<Spinner />}>
              <Feedback layout={layout} />
            </Suspense>
          ),
        },
        {
          path: Routes.RETURN,
          element: (
            <Suspense fallback={<Spinner />}>
              <Return layout={layout} />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: <Navigate to={`${Routes.HOME}`} replace />,
        },
      ],
    },
  ]);
};

/**
 * Provided useRoutes is not stable (when integrated with Sentry) as it's rendering a different component tree.
 *
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/reactrouterv6.tsx#L221
 * (SentryRoutes is a new component after each re-render)
 */
const MemoizedRouting = memo(Routing);
export { MemoizedRouting as Routing };
