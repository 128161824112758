import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PressPricingTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";
import { PROJECT, TrackingPage } from "./tracking";

interface PressPricingFunctionArgs {
  readonly collapse: boolean;
}
interface PressPricingFunction {
  (args: PressPricingFunctionArgs): void;
}

interface UseTrackPressPricingFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}
interface UseTrackPressPricingFunction {
  (agrs: UseTrackPressPricingFunctionArgs): PressPricingFunction;
}

const useTrackPressPricing: UseTrackPressPricingFunction = ({ page, country, segment, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<PressPricingTrackingEvent>();

  const pressPricing: PressPricingFunction = useCallback(
    ({ collapse }) => {
      if (!checkoutId) {
        return;
      }

      const pressPricingTrackingEvent: PressPricingTrackingEvent = {
        event: TrackingEventName.PRESS_PRICING,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        collapse,
      };

      emitUserEvent(pressPricingTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, page, segment],
  );

  return pressPricing;
};

export { useTrackPressPricing };
