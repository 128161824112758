import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space2, space4, space6, colorBorderInput, fontDetailSize1, fontDetailHeight1 } = theme();

const style = StyleSheet.create({
  carousel: {
    paddingBottom: space6,
    paddingHorizontal: space6,
    paddingTop: space2,
  },
  carouselDesktop: {
    paddingHorizontal: 0,
  },
  emptyText: {
    paddingTop: space4,
  },
  tabList: {
    borderBottomColor: colorBorderInput,
    borderBottomWidth: 1,
    justifyContent: "flex-start",
    paddingHorizontal: space4,
  },
  tabListDesktop: {
    paddingHorizontal: 0,
  },
  tabText: {
    fontSize: fontDetailSize1,
    lineHeight: fontDetailHeight1,
  },
});

export { style };
