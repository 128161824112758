import { StyleSheet, Platform } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space1, space4, space6, borderRadius2, borderWidth1 } = theme();

const style = StyleSheet.create({
  error: {
    marginLeft: space6,
    marginTop: space1,
  },
  field: {
    left: space4,
    maxWidth: "100%",
    position: "absolute",
    zIndex: 4,
  },
  icon: {
    height: 16,
    position: "absolute",
    right: space4,
    top: space4,
    width: 16,
    zIndex: 4,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  input: {
    ...Platform.select({
      web: {
        textOverflow: "ellipsis",
      },
    }),
  },
  inputField: {
    borderRadius: borderRadius2,
    borderWidth: borderWidth1,
  },
});

export { style };
