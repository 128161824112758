import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, PressItemTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface PressItemFunctionArgs {
  readonly checkoutItemId: string;
}
interface PressItemFunction {
  (args: PressItemFunctionArgs): void;
}

interface UseTrackPressItemFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}
interface UseTrackPressItemFunction {
  (agrs: UseTrackPressItemFunctionArgs): PressItemFunction;
}

const useTrackPressItem: UseTrackPressItemFunction = ({ page, country, segment, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<PressItemTrackingEvent>();

  const pressItem: PressItemFunction = useCallback(
    ({ checkoutItemId }) => {
      if (!checkoutId) {
        return;
      }

      const pressItemTrackingEvent: PressItemTrackingEvent = {
        event: TrackingEventName.PRESS_ITEM,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        checkoutItemId,
      };

      emitUserEvent(pressItemTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, page, segment],
  );

  return pressItem;
};

export { useTrackPressItem };
