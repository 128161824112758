import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space2, space6 } = theme();

const style = StyleSheet.create({
  modalContent: {
    paddingHorizontal: space6,
  },
  optionText: {
    marginBottom: space6,
  },
  wrapper: {
    marginBottom: space2,
  },
});

export { style };
