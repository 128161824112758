import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import {
  PROJECT,
  TrackingPage,
  PressPreviousTrackingEvent,
  TrackingEventName,
  TrackingEventCategory,
} from "./tracking";

interface PressPreviousFunctionArgs {
  readonly from: string;
  readonly to: string;
}
interface PressPreviousFunction {
  (args: PressPreviousFunctionArgs): void;
}

interface UseTrackPressPreviousFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}

interface UseTrackPressPreviousFunction {
  (args: UseTrackPressPreviousFunctionArgs): PressPreviousFunction;
}

const useTrackPressPrevious: UseTrackPressPreviousFunction = ({ page, country, segment, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<PressPreviousTrackingEvent>();

  const pressPrevious: PressPreviousFunction = useCallback(
    ({ from, to }) => {
      if (!checkoutId) {
        return;
      }

      const pressPreviousTrackingEvent: PressPreviousTrackingEvent = {
        event: TrackingEventName.PRESS_PREVIOUS,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        from,
        to,
      };

      emitUserEvent(pressPreviousTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, page, segment],
  );

  return pressPrevious;
};

export { useTrackPressPrevious };
