import React, { useCallback, useMemo } from "react";
import { useIntl } from "@lookiero/i18n-react";
import { InputField } from "../../../../../../../shared/ui/components/molecules/inputField/InputField";
import { useCheckoutQuestionFeedbackForId } from "../../behaviors/useCheckoutQuestionFeedback";
import { CheckoutQuestionItem, CheckoutQuestionItemProps } from "../CheckoutQuestionItem";

const TextareaCheckoutQuestionItem: CheckoutQuestionItem = ({
  checkoutQuestion,
  checkoutQuestionParentId,
  testID,
}: CheckoutQuestionItemProps) => {
  const { formatMessage } = useIntl();
  const placeholderText = useMemo(
    () => (checkoutQuestion.placeholder ? formatMessage({ id: checkoutQuestion.placeholder }) : ""),
    [formatMessage, checkoutQuestion.placeholder],
  );

  const { feedback, onChange } = useCheckoutQuestionFeedbackForId({ id: checkoutQuestionParentId });
  const handleOnChange = useCallback(
    (value: string) => onChange({ checkoutQuestionId: checkoutQuestionParentId, checkoutQuestionFeedback: value }),
    [onChange, checkoutQuestionParentId],
  );

  return (
    <InputField
      label={placeholderText}
      placeholder={placeholderText}
      testID={testID}
      value={feedback}
      multiline
      onChange={handleOnChange}
    />
  );
};

export { TextareaCheckoutQuestionItem };
