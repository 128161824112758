import {
  AggregateRoot,
  RepositoryGetFunction,
  RepositoryGetFunctionArgs,
  RepositorySaveFunction,
  RepositorySaveFunctionArgs,
} from "@lookiero/messaging";
import { bootstrap as messagingBootstrap, BuildBootstrapFunctionReturn } from "@lookiero/messaging-react";
import {
  bootstrapWithBuilder as bootstrapNotifications,
  inMemoryStorageNotifications,
} from "@lookiero/sty-psp-notifications";
import { START_CHECKOUT } from "../../domain/checkout/command/startCheckout";
import { SUBMIT_CHECKOUT } from "../../domain/checkout/command/submitCheckout";
import { createModalNotificationWhenCheckoutSubmitted } from "../../domain/checkout/event/createModalNotificationWhenCheckoutSubmitted";
import { Checkout, submitCheckoutHandler, startCheckoutHandler } from "../../domain/checkout/model/checkout";
import { CHECKOUT_SUBMITTED } from "../../domain/checkout/model/checkoutSubmitted";
import { CheckoutsGetFunction, CheckoutsSaveFunction } from "../../domain/checkout/model/checkouts";
import { BLOCK_CHECKOUT_BOOKING } from "../../domain/checkoutBooking/command/blockCheckoutBooking";
import { BOOK_CHECKOUT_BOOKING_FOR_CHECKOUT_ITEM } from "../../domain/checkoutBooking/command/bookCheckoutBookingForCheckoutItem";
import { createToastNotificationWhenCheckoutBookingExpired } from "../../domain/checkoutBooking/event/createToastNotificationWhenCheckoutBookingExpired";
import {
  blockCheckoutBookingHandler,
  bookCheckoutBookingForCheckoutItemHandler,
  CheckoutBooking,
} from "../../domain/checkoutBooking/model/checkoutBooking";
import { CHECKOUT_BOOKING_EXPIRED } from "../../domain/checkoutBooking/model/checkoutBookingExpired";
import {
  CheckoutBookingsGetFunction,
  CheckoutBookingsSaveFunction,
} from "../../domain/checkoutBooking/model/checkoutBookings";
import { GIVE_CHECKOUT_FEEDBACK } from "../../domain/checkoutFeedback/command/giveCheckoutFeedback";
import { CheckoutFeedback, giveCheckoutFeedbackHandler } from "../../domain/checkoutFeedback/model/checkoutFeedback";
import {
  CheckoutFeedbacksGetFunction,
  CheckoutFeedbacksSaveFunction,
} from "../../domain/checkoutFeedback/model/checkoutFeedbacks";
import { KEEP_CHECKOUT_ITEM } from "../../domain/checkoutItem/command/keepCheckoutItem";
import { REPLACE_CHECKOUT_ITEM } from "../../domain/checkoutItem/command/replaceCheckoutItem";
import { RESET_CHECKOUT_ITEM } from "../../domain/checkoutItem/command/resetCheckoutItem";
import { RETURN_CHECKOUT_ITEM } from "../../domain/checkoutItem/command/returnCheckoutItem";
import { createModalNotificationWhenCheckoutItemReplaced } from "../../domain/checkoutItem/event/createModalNotificationWhenCheckoutItemReplaced";
import {
  CheckoutItem,
  keepCheckoutItemHandler,
  replaceCheckoutItemHandler,
  resetCheckoutItemHandler,
  returnCheckoutItemHandler,
} from "../../domain/checkoutItem/model/checkoutItem";
import { CHECKOUT_ITEM_REPLACED } from "../../domain/checkoutItem/model/checkoutItemReplaced";
import { CheckoutItemsGetFunction, CheckoutItemsSaveFunction } from "../../domain/checkoutItem/model/checkoutItems";
import { UPDATE_UI_SETTING } from "../../domain/uiSetting/command/updateUiSetting";
import { UiSetting, updateUiSettingHandler } from "../../domain/uiSetting/model/uiSetting";
import { UiSettingGetFunction, UiSettingSaveFunction } from "../../domain/uiSetting/model/uiSettings";
import {
  BookedProductsVariantsForCheckoutItemView,
  viewBookedProductsVariantsForCheckoutItemHandler,
  VIEW_BOOKED_PRODUCT_VARIANTS_FOR_CHECKOUT_ITEM,
} from "../../projection/bookedProductsVariants/viewBookedProductVariantsForCheckoutItem";
import {
  CheckoutByIdView,
  viewCheckoutByIdHandler,
  VIEW_CHECKOUT_BY_ID,
} from "../../projection/checkout/viewCheckoutById";
import {
  FirstAvailableCheckoutByCustomerIdView,
  viewFirstAvailableCheckoutByCustomerIdHandler,
  VIEW_FIRST_AVAILABLE_CHECKOUT_BY_CUSTOMER_ID,
} from "../../projection/checkout/viewFirstAvailableCheckoutByCustomerId";
import {
  FiveItemsDiscountByCustomerIdView,
  viewFiveItemsDiscountByCustomerIdHandler,
  VIEW_FIVE_ITEMS_DISCOUNT_BY_CUSTOMER_ID,
} from "../../projection/checkout/viewFiveItemsDiscountByCustomerId";
import {
  IsCheckoutEnabledByCustomerIdView,
  viewIsCheckoutEnabledByCustomerIdHandler,
  VIEW_IS_CHECKOUT_ENABLED_BY_CUSTOMER_ID,
} from "../../projection/checkout/viewIsCheckoutEnabledByCustomerId";
import {
  IsSizeChangeEnabledByCheckoutIdView,
  viewIsSizeChangeEnabledByCheckoutIdHandler,
  VIEW_IS_SIZE_CHANGE_ENABLED_BY_CHECKOUT_ID,
} from "../../projection/checkout/viewIsSizeChangeEnabledByCheckoutId";
import {
  CheckoutBookingByIdView,
  viewCheckoutBookingByIdHandler,
  VIEW_CHECKOUT_BOOKING_BY_ID,
} from "../../projection/checkoutBooking/viewCheckoutBookingById";
import {
  viewCheckoutItemByIdHandler,
  VIEW_CHECKOUT_ITEM_BY_ID,
  CheckoutItemByIdView,
} from "../../projection/checkoutItem/viewCheckoutItemById";
import {
  CheckoutQuestionsByCheckoutIdView,
  listCheckoutQuestionsByCheckoutIdHandler,
  LIST_CHECKOUT_QUESTIONS_BY_CHECKOUT_ID,
} from "../../projection/checkoutQuestion/listCheckoutQuestionsByCheckoutId";
import {
  PaymentFlowPayloadByCheckoutIdView,
  viewPaymentFlowPayloadByCheckoutIdHandler,
  VIEW_PAYMENT_FLOW_PAYLOAD_BY_CHECKOUT_ID,
} from "../../projection/payment/viewPaymentFlowPayloadByCheckoutId";
import {
  PricingByCheckoutIdView,
  viewPricingByCheckoutIdHandler,
  VIEW_PRICING_BY_CHECKOUT_ID,
} from "../../projection/pricing/viewPricingByCheckoutId";
import {
  listReturnQuestionsByCheckoutItemIdHandler,
  LIST_RETURN_QUESTIONS_BY_CHECKOUT_ITEM_ID,
  ReturnQuestionsByCheckoutItemIdView,
} from "../../projection/returnQuestion/listReturnQuestionsByCheckoutItemId";
import {
  UiSettingByKeyView,
  viewUiSettingByKeyHandler,
  VIEW_UI_SETTING_BY_KEY,
} from "../../projection/uiSetting/viewUiSettingByKey";

const MESSAGING_CONTEXT_ID = "Checkout";

type NeverWhenEmptyRecord<K extends [Record<string, unknown>]> = K extends [Record<string, never>] ? [never?] : K;

type RepositoryDependencies<
  A extends AggregateRoot,
  GetFunctionArgs extends RepositoryGetFunctionArgs,
  SaveFunctionArgs extends RepositorySaveFunctionArgs,
> = Omit<
  Parameters<RepositoryGetFunction<A, GetFunctionArgs>>[0] & Parameters<RepositorySaveFunction<A, SaveFunctionArgs>>[0],
  keyof RepositoryGetFunctionArgs
>;

interface BaseBootstrapFunctionArgs<
  UiSettingGetFunctionArgs extends RepositoryGetFunctionArgs,
  UiSettingSaveFunctionArgs extends RepositorySaveFunctionArgs,
  CheckoutGetFunctionArgs extends RepositoryGetFunctionArgs,
  CheckoutSaveFunctionArgs extends RepositorySaveFunctionArgs,
  CheckoutItemGetFunctionArgs extends RepositoryGetFunctionArgs,
  CheckoutItemSaveFunctionArgs extends RepositorySaveFunctionArgs,
  CheckoutBookingGetFunctionArgs extends RepositoryGetFunctionArgs,
  CheckoutBookingSaveFunctionArgs extends RepositorySaveFunctionArgs,
  CheckoutFeedbackGetFunctionArgs extends RepositoryGetFunctionArgs,
  CheckoutFeedbackSaveFunctionArgs extends RepositorySaveFunctionArgs,
> {
  readonly checkoutByIdView: CheckoutByIdView;
  readonly firstAvailableCheckoutByCustomerIdView: FirstAvailableCheckoutByCustomerIdView;
  readonly isCheckoutEnabledByCustomerIdView: IsCheckoutEnabledByCustomerIdView;
  readonly isSizeChangeEnabledByCheckoutIdView: IsSizeChangeEnabledByCheckoutIdView;
  readonly fiveItemsDiscountByCustomerIdView: FiveItemsDiscountByCustomerIdView;
  readonly uiSettingByKeyView: UiSettingByKeyView;
  readonly checkoutItemByIdView: CheckoutItemByIdView;
  readonly checkoutBookingByIdView: CheckoutBookingByIdView;
  readonly returnQuestionsByCheckoutItemIdView: ReturnQuestionsByCheckoutItemIdView;
  readonly bookedProductsVariantsForCheckoutItemView: BookedProductsVariantsForCheckoutItemView;
  readonly pricingByCheckoutIdView: PricingByCheckoutIdView;
  readonly paymentFlowPayloadByCheckoutIdView: PaymentFlowPayloadByCheckoutIdView;
  readonly checkoutQuestionsByCheckoutIdView: CheckoutQuestionsByCheckoutIdView;
  readonly getUiSetting: UiSettingGetFunction<UiSettingGetFunctionArgs>;
  readonly saveUiSetting: UiSettingSaveFunction<UiSettingSaveFunctionArgs>;
  readonly uiSettingsDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<UiSetting, UiSettingGetFunctionArgs, UiSettingSaveFunctionArgs>]
  >;
  readonly getCheckout: CheckoutsGetFunction<CheckoutGetFunctionArgs>;
  readonly saveCheckout: CheckoutsSaveFunction<CheckoutSaveFunctionArgs>;
  readonly checkoutsDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<Checkout, CheckoutGetFunctionArgs, CheckoutSaveFunctionArgs>]
  >;
  readonly getCheckoutItem: CheckoutItemsGetFunction<CheckoutItemGetFunctionArgs>;
  readonly saveCheckoutItem: CheckoutItemsSaveFunction<CheckoutItemSaveFunctionArgs>;
  readonly checkoutItemsDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<CheckoutItem, CheckoutItemGetFunctionArgs, CheckoutItemSaveFunctionArgs>]
  >;
  readonly getCheckoutBooking: CheckoutBookingsGetFunction<CheckoutBookingGetFunctionArgs>;
  readonly saveCheckoutBooking: CheckoutBookingsSaveFunction<CheckoutBookingSaveFunctionArgs>;
  readonly checkoutBookingsDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<CheckoutBooking, CheckoutBookingGetFunctionArgs, CheckoutBookingSaveFunctionArgs>]
  >;
  readonly getCheckoutFeedback: CheckoutFeedbacksGetFunction<CheckoutFeedbackGetFunctionArgs>;
  readonly saveCheckoutFeedback: CheckoutFeedbacksSaveFunction<CheckoutFeedbackSaveFunctionArgs>;
  readonly checkoutFeedbacksDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<CheckoutFeedback, CheckoutFeedbackGetFunctionArgs, CheckoutFeedbackSaveFunctionArgs>]
  >;
}

interface BaseBootstrapFunction {
  <
    UiSettingGetArgs extends RepositoryGetFunctionArgs,
    UiSettingSaveArgs extends RepositorySaveFunctionArgs,
    CheckoutGetFunctionArgs extends RepositoryGetFunctionArgs,
    CheckoutSaveFunctionArgs extends RepositorySaveFunctionArgs,
    CheckoutItemGetFunctionArgs extends RepositoryGetFunctionArgs,
    CheckoutItemSaveFunctionArgs extends RepositorySaveFunctionArgs,
    CheckoutBookingGetFunctionArgs extends RepositoryGetFunctionArgs,
    CheckoutBookingSaveFunctionArgs extends RepositorySaveFunctionArgs,
    CheckoutFeedbackGetFunctionArgs extends RepositoryGetFunctionArgs,
    CheckoutFeedbackSaveFunctionArgs extends RepositorySaveFunctionArgs,
  >(
    args: BaseBootstrapFunctionArgs<
      UiSettingGetArgs,
      UiSettingSaveArgs,
      CheckoutGetFunctionArgs,
      CheckoutSaveFunctionArgs,
      CheckoutItemGetFunctionArgs,
      CheckoutItemSaveFunctionArgs,
      CheckoutBookingGetFunctionArgs,
      CheckoutBookingSaveFunctionArgs,
      CheckoutFeedbackGetFunctionArgs,
      CheckoutFeedbackSaveFunctionArgs
    >,
  ): BuildBootstrapFunctionReturn;
}

const baseBootstrap: BaseBootstrapFunction = ({
  checkoutByIdView,
  firstAvailableCheckoutByCustomerIdView,
  isCheckoutEnabledByCustomerIdView,
  isSizeChangeEnabledByCheckoutIdView,
  fiveItemsDiscountByCustomerIdView,
  checkoutItemByIdView,
  returnQuestionsByCheckoutItemIdView,
  bookedProductsVariantsForCheckoutItemView,
  checkoutBookingByIdView,
  pricingByCheckoutIdView,
  paymentFlowPayloadByCheckoutIdView,
  checkoutQuestionsByCheckoutIdView,
  uiSettingByKeyView,
  getUiSetting,
  saveUiSetting,
  uiSettingsDependencies,
  getCheckout,
  saveCheckout,
  checkoutsDependencies,
  getCheckoutItem,
  saveCheckoutItem,
  checkoutItemsDependencies,
  getCheckoutBooking,
  saveCheckoutBooking,
  checkoutBookingsDependencies,
  getCheckoutFeedback,
  saveCheckoutFeedback,
  checkoutFeedbacksDependencies,
}) => {
  let messaging = messagingBootstrap({ id: MESSAGING_CONTEXT_ID })
    .query(VIEW_FIVE_ITEMS_DISCOUNT_BY_CUSTOMER_ID, viewFiveItemsDiscountByCustomerIdHandler, {
      view: fiveItemsDiscountByCustomerIdView,
    })
    .query(VIEW_CHECKOUT_BY_ID, viewCheckoutByIdHandler, {
      view: checkoutByIdView,
    })
    .query(VIEW_FIRST_AVAILABLE_CHECKOUT_BY_CUSTOMER_ID, viewFirstAvailableCheckoutByCustomerIdHandler, {
      view: firstAvailableCheckoutByCustomerIdView,
    })
    .query(VIEW_IS_CHECKOUT_ENABLED_BY_CUSTOMER_ID, viewIsCheckoutEnabledByCustomerIdHandler, {
      view: isCheckoutEnabledByCustomerIdView,
    })
    .query(VIEW_IS_SIZE_CHANGE_ENABLED_BY_CHECKOUT_ID, viewIsSizeChangeEnabledByCheckoutIdHandler, {
      view: isSizeChangeEnabledByCheckoutIdView,
    })
    .command(START_CHECKOUT, startCheckoutHandler)(getCheckout, saveCheckout, ...checkoutsDependencies)
    .command(SUBMIT_CHECKOUT, submitCheckoutHandler)(getCheckout, saveCheckout, ...checkoutsDependencies)
    .query(VIEW_CHECKOUT_ITEM_BY_ID, viewCheckoutItemByIdHandler, {
      view: checkoutItemByIdView,
    })
    .query(LIST_RETURN_QUESTIONS_BY_CHECKOUT_ITEM_ID, listReturnQuestionsByCheckoutItemIdHandler, {
      view: returnQuestionsByCheckoutItemIdView,
    })
    .command(KEEP_CHECKOUT_ITEM, keepCheckoutItemHandler)(
      getCheckoutItem,
      saveCheckoutItem,
      ...checkoutItemsDependencies,
    )
    .command(RETURN_CHECKOUT_ITEM, returnCheckoutItemHandler)(
      getCheckoutItem,
      saveCheckoutItem,
      ...checkoutItemsDependencies,
    )
    .command(REPLACE_CHECKOUT_ITEM, replaceCheckoutItemHandler)(
      getCheckoutItem,
      saveCheckoutItem,
      ...checkoutItemsDependencies,
    )
    .command(RESET_CHECKOUT_ITEM, resetCheckoutItemHandler)(
      getCheckoutItem,
      saveCheckoutItem,
      ...checkoutItemsDependencies,
    )
    .query(VIEW_PRICING_BY_CHECKOUT_ID, viewPricingByCheckoutIdHandler, {
      view: pricingByCheckoutIdView,
    })
    .query(VIEW_PAYMENT_FLOW_PAYLOAD_BY_CHECKOUT_ID, viewPaymentFlowPayloadByCheckoutIdHandler, {
      view: paymentFlowPayloadByCheckoutIdView,
    })
    .query(LIST_CHECKOUT_QUESTIONS_BY_CHECKOUT_ID, listCheckoutQuestionsByCheckoutIdHandler, {
      view: checkoutQuestionsByCheckoutIdView,
    })
    .query(VIEW_CHECKOUT_BOOKING_BY_ID, viewCheckoutBookingByIdHandler, {
      view: checkoutBookingByIdView,
    })
    .query(VIEW_BOOKED_PRODUCT_VARIANTS_FOR_CHECKOUT_ITEM, viewBookedProductsVariantsForCheckoutItemHandler, {
      view: bookedProductsVariantsForCheckoutItemView,
    })
    .command(BOOK_CHECKOUT_BOOKING_FOR_CHECKOUT_ITEM, bookCheckoutBookingForCheckoutItemHandler)(
      getCheckoutBooking,
      saveCheckoutBooking,
      ...checkoutBookingsDependencies,
    )
    .command(BLOCK_CHECKOUT_BOOKING, blockCheckoutBookingHandler)(
      getCheckoutBooking,
      saveCheckoutBooking,
      ...checkoutBookingsDependencies,
    )
    .command(GIVE_CHECKOUT_FEEDBACK, giveCheckoutFeedbackHandler)(
      getCheckoutFeedback,
      saveCheckoutFeedback,
      ...checkoutFeedbacksDependencies,
    )
    .query(VIEW_UI_SETTING_BY_KEY, viewUiSettingByKeyHandler, {
      view: uiSettingByKeyView,
    })
    .command(UPDATE_UI_SETTING, updateUiSettingHandler)(getUiSetting, saveUiSetting, ...uiSettingsDependencies)
    .processManager(CHECKOUT_BOOKING_EXPIRED, createToastNotificationWhenCheckoutBookingExpired)
    .processManager(CHECKOUT_ITEM_REPLACED, createModalNotificationWhenCheckoutItemReplaced)
    .processManager(CHECKOUT_SUBMITTED, createModalNotificationWhenCheckoutSubmitted);

  messaging = bootstrapNotifications({ messaging, storage: inMemoryStorageNotifications() });

  return messaging.build();
};

export { baseBootstrap, MESSAGING_CONTEXT_ID };
