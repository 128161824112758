import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isCheckoutBookingBooked } from "../../../../domain/checkoutBooking/model/checkoutBookingBooked";
import { isCheckoutBookingExpired } from "../../../../domain/checkoutBooking/model/checkoutBookingExpired";
import { BookedProductsVariantsProjection } from "../../../../projection/bookedProductsVariants/bookedProductsVariants";
import { viewBookedProductsVariantsForCheckoutItem } from "../../../../projection/bookedProductsVariants/viewBookedProductVariantsForCheckoutItem";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewBookedProductsVariantsForCheckoutItemFunctionArgs {
  readonly checkoutItemId: string;
}

interface UseViewBookedProductsVariantsForCheckoutItemFunction {
  (
    args: UseViewBookedProductsVariantsForCheckoutItemFunctionArgs,
  ): UseQueryFunctionResult<BookedProductsVariantsProjection | null>;
}

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isCheckoutBookingBooked(event) || isCheckoutBookingExpired(event);

const useViewBookedProductsVariantsForCheckoutItem: UseViewBookedProductsVariantsForCheckoutItemFunction = ({
  checkoutItemId,
}) =>
  useQuery({
    query: viewBookedProductsVariantsForCheckoutItem({ checkoutItemId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: { refetchOnMount: "always", staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useViewBookedProductsVariantsForCheckoutItem };
