import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space4, colorBgPrimary } = theme();

const style = StyleSheet.create({
  divider: {
    backgroundColor: colorBgPrimary,
    height: 1,
    marginBottom: space4,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: space4,
  },
});

export { style };
