import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, space6 } = theme();

const style = StyleSheet.create({
  wrapper: {
    backgroundColor: colorBgBase,
    padding: space6,
    paddingTop: 0,
  },
});

export { style };
