import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderRadius4, colorBgBase, colorBgPrimaryMediumLight, colorBorderActionSecondary, space4 } = theme();

const PAGINATION_SIZE = 37;

const style = StyleSheet.create({
  image: {
    flex: 1,
  },
  largeImage: {
    borderRadius: borderRadius4,
  },
  paginationActiveItemStyle: {
    borderColor: colorBorderActionSecondary,
  },
  paginationImage: {
    flex: 1,
  },
  paginationItemStyle: {
    backgroundColor: colorBgBase,
    borderColor: colorBgPrimaryMediumLight,
    borderRadius: 0,
    borderWidth: 1,
    height: PAGINATION_SIZE,
    width: PAGINATION_SIZE,
  },
  paginationStyle: {
    bottom: 0,
    marginTop: space4,
    position: "relative",
  },
});

export { style };
