import { Country } from "@lookiero/sty-psp-locale";

interface SizeProjection {
  readonly id: string;
  readonly lookiero: string;
  readonly uk: string;
  readonly it: string;
  readonly europe: string;
  readonly unique: boolean;
  readonly visualOrder?: number;
}

interface SizeFunctionArgs {
  readonly size: SizeProjection;
  readonly country: Country;
}

interface SizeFunction {
  (args: SizeFunctionArgs): string;
}

const size: SizeFunction = ({ size, country }) => (country === Country.GB ? size.uk : size.lookiero);

export type { SizeProjection };
export { size };
