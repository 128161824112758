import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { FeedbackProjection } from "../../../../../../projection/feedback/feedback";
import { ReturnQuestionProjection } from "../../../../../../projection/returnQuestion/returnQuestion";
import { isChildReturnQuestion } from "./isChildReturnQuestion";

interface OnChangeReturnQuestionFeedbackFunctionArgs {
  readonly returnQuestionId: string;
  readonly returnQuestionFeedback: string | undefined; // uuid | free-text
}

interface OnChangeReturnQuestionFeedbackFunction {
  (args: OnChangeReturnQuestionFeedbackFunctionArgs): void;
}

interface ClearReturnQuestionFeedbackFunctionArgs {
  readonly returnQuestion: ReturnQuestionProjection;
}

interface ClearReturnQuestionFeedbackFunction {
  (args: ClearReturnQuestionFeedbackFunctionArgs): void;
}

interface ReturnQuestionFeedbackContextShape {
  readonly feedback: FeedbackProjection;
  readonly values: Record<string, string | undefined>;
  readonly onChange: OnChangeReturnQuestionFeedbackFunction;
  readonly clear: ClearReturnQuestionFeedbackFunction;
  readonly setValues: (values: Record<string, string | undefined>) => void;
}

const ReturnQuestionFeedbackContext = createContext<ReturnQuestionFeedbackContextShape>(
  null as unknown as ReturnQuestionFeedbackContextShape,
);

interface ReturnQuestionFeedbackContextProviderProps {
  readonly feedback: FeedbackProjection | undefined;
  readonly children: ReactNode;
}

const ReturnQuestionFeedbackProvider: FC<ReturnQuestionFeedbackContextProviderProps> = ({
  feedback = {},
  children,
}) => {
  const [contextFeedback, setContextFeedback] = useState<FeedbackProjection>(feedback);
  const [contextValues, setContextValues] = useState<Record<string, string | undefined>>({});
  const onChange = useCallback<OnChangeReturnQuestionFeedbackFunction>(
    ({ returnQuestionId, returnQuestionFeedback }) => {
      setContextFeedback((feedback) =>
        returnQuestionFeedback
          ? { ...feedback, [returnQuestionId]: returnQuestionFeedback }
          : Object.entries(feedback).reduce(
              (acc, [id, feedback]) => (id !== returnQuestionId ? { ...acc, [id]: feedback } : acc),
              {},
            ),
      );
    },
    [],
  );

  const clear = useCallback<ClearReturnQuestionFeedbackFunction>(
    ({ returnQuestion }) =>
      setContextFeedback((feedback) =>
        Object.fromEntries(
          Object.entries(feedback).filter(
            ([feedbackReturnQuestionId]) =>
              !isChildReturnQuestion({
                returnQuestionId: feedbackReturnQuestionId,
                returnQuestion,
              }),
          ),
        ),
      ),
    [],
  );

  const setValues = useCallback((values: Record<string, string | undefined>) => {
    setContextValues(values);
  }, []);

  useEffect(() => setContextFeedback(feedback), [feedback]);

  const value = useMemo(
    () => ({
      feedback: contextFeedback,
      onChange,
      clear,
      values: contextValues,
      setValues,
    }),
    [clear, contextFeedback, contextValues, onChange, setValues],
  );

  return <ReturnQuestionFeedbackContext.Provider value={value}>{children}</ReturnQuestionFeedbackContext.Provider>;
};

interface UseReturnQuestionFeedbackForReturnQuestionFunctionArgs {
  readonly returnQuestion: ReturnQuestionProjection;
}

interface UseReturnQuestionFeedbackForReturnQuestionFunction {
  (args: UseReturnQuestionFeedbackForReturnQuestionFunctionArgs): {
    readonly feedback: string | undefined;
    readonly onChange: OnChangeReturnQuestionFeedbackFunction;
    readonly clear: () => void;
  };
}

const useReturnQuestionFeedbackForReturnQuestion: UseReturnQuestionFeedbackForReturnQuestionFunction = ({
  returnQuestion,
}) => {
  const returnQuestionFeedbackContext = useContext<ReturnQuestionFeedbackContextShape>(ReturnQuestionFeedbackContext);

  invariant(
    returnQuestionFeedbackContext,
    "Your are trying to use the useReturnQuestionFeedbackForReturnQuestion hook without wrapping your app with the <ReturnQuestionFeedbackProvider>.",
  );

  const { feedback, onChange, clear } = returnQuestionFeedbackContext;

  const clearReturnQuestionFeedback = useCallback(() => clear({ returnQuestion }), [clear, returnQuestion]);

  return {
    feedback: feedback[returnQuestion.id],
    onChange,
    clear: clearReturnQuestionFeedback,
  };
};

interface UseReturnQuestionFeedbackFunction {
  (): FeedbackProjection;
}

const useReturnQuestionFeedback: UseReturnQuestionFeedbackFunction = () => {
  const returnQuestionFeedbackContext = useContext<ReturnQuestionFeedbackContextShape>(ReturnQuestionFeedbackContext);

  invariant(
    returnQuestionFeedbackContext,
    "Your are trying to use the useReturnQuestionFeedback hook without wrapping your app with the <ReturnQuestionFeedbackProvider>.",
  );

  const { feedback } = returnQuestionFeedbackContext;

  return feedback;
};

export { useReturnQuestionFeedbackForReturnQuestion, useReturnQuestionFeedback, ReturnQuestionFeedbackProvider };
