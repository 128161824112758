import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorTextDisabled, space3, space2, space4, space6 } = theme();

const style = StyleSheet.create({
  actionsRow: {
    alignItems: "center",
    flexDirection: "row",
    marginBottom: space4,
  },
  inputDisabled: {
    color: colorTextDisabled,
  },
  returnButton: {
    flex: 1,
  },
  row: {
    paddingHorizontal: space3,
  },
  sizeSelector: {
    flex: 1,
    marginRight: space2,
  },
  sticky: {
    paddingBottom: space6,
    paddingHorizontal: 0,
    paddingTop: space6,
  },
});

export { style };
