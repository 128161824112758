import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderWidth1, colorBorderInteractive, space1, space5, space6 } = theme();

const style = StyleSheet.create({
  modalContent: {
    paddingBottom: space6,
    paddingHorizontal: space6,
  },
  modalTitle: {
    marginBottom: space1,
  },
  option: {
    borderBottomColor: colorBorderInteractive,
    borderBottomWidth: borderWidth1,
    paddingVertical: space5,
  },
  optionText: {
    lineHeight: space6,
  },
});

export { style };
