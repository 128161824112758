import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, ResetItemTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface ResetItemFunction {
  (): void;
}

interface UseTrackResetItemFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
  readonly checkoutItemId: string;
}
interface UseTrackResetItemFunction {
  (agrs: UseTrackResetItemFunctionArgs): ResetItemFunction;
}

const useTrackResetItem: UseTrackResetItemFunction = ({ page, country, segment, checkoutId, checkoutItemId }) => {
  const emitUserEvent = useEmitUserEvent<ResetItemTrackingEvent>();

  const resetItem: ResetItemFunction = useCallback(() => {
    if (!checkoutId) {
      return;
    }

    const resetItemTrackingEvent: ResetItemTrackingEvent = {
      event: TrackingEventName.RESET_ITEM,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${page}`,
      store: country,
      segment,
      checkoutId,
      checkoutItemId,
    };

    emitUserEvent(resetItemTrackingEvent);
  }, [checkoutId, checkoutItemId, country, emitUserEvent, page, segment]);

  return resetItem;
};

export { useTrackResetItem };
