import React from "react";
import ReturnQuestion from "../../../ReturnQuestion";
import { ReturnQuestionItem, ReturnQuestionItemProps } from "../../ReturnQuestionItem";

const HostStackReturnQuestionItem: ReturnQuestionItem = ({
  returnQuestion,
  portalHostName,
}: ReturnQuestionItemProps) => (
  <>
    {returnQuestion.children?.map((childReturnQuestion) => (
      <ReturnQuestion
        key={childReturnQuestion.id}
        portalHostName={portalHostName}
        returnQuestion={childReturnQuestion}
        returnQuestionParent={returnQuestion}
      />
    ))}
  </>
);

export { HostStackReturnQuestionItem };
