import React, { FC, ReactNode, useCallback, useState } from "react";
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from "react-native";

interface Dimension {
  readonly width?: number | undefined;
  readonly height?: number | undefined;
}

enum AspectRatioViewResizeDirection {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

interface AspectRatioViewProps {
  readonly aspectRatio?: number;
  readonly resizeDirection?: AspectRatioViewResizeDirection;
  readonly multiplier?: number;
  readonly children: ReactNode;
  readonly style?: StyleProp<ViewStyle>;
}
const AspectRatioView: FC<AspectRatioViewProps> = ({
  aspectRatio = 1,
  resizeDirection = AspectRatioViewResizeDirection.HORIZONTAL,
  multiplier = 1,
  style: customStyle = {},
  children,
}) => {
  const [{ width, height }, setDimension] = useState<Dimension>({});
  const handleOnLayout = useCallback(
    ({ nativeEvent: { layout } }: LayoutChangeEvent) =>
      setDimension(
        resizeDirection === AspectRatioViewResizeDirection.HORIZONTAL
          ? { width: layout.width * multiplier, height: layout.width * aspectRatio }
          : { width: layout.height * aspectRatio, height: layout.height },
      ),
    [aspectRatio, multiplier, resizeDirection],
  );

  return (
    <View onLayout={handleOnLayout}>
      <View style={[{ width, height }, customStyle]}>{children}</View>
    </View>
  );
};

export { AspectRatioView, AspectRatioViewResizeDirection };
