import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorTextMedium, space1, space4 } = theme();

const style = StyleSheet.create({
  container: {
    marginBottom: space4,
  },
  title: {
    color: colorTextMedium,
    marginBottom: space1,
  },
});

export { style };
