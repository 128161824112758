import React, { FC, memo } from "react";
import { ReturnQuestionProjection } from "../../../../../projection/returnQuestion/returnQuestion";
import { useReturnQuestionItem } from "./behaviors/useReturnQuestionItem";

interface ReturnQuestionProps {
  readonly returnQuestionParent: ReturnQuestionProjection;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly portalHostName?: string;
}

const ReturnQuestion: FC<ReturnQuestionProps> = ({ returnQuestionParent, returnQuestion, portalHostName }) => {
  const Item = useReturnQuestionItem({ type: returnQuestion.type });

  return (
    <Item
      portalHostName={portalHostName}
      returnQuestion={returnQuestion}
      returnQuestionParent={returnQuestionParent}
      testID={returnQuestion.id}
    >
      <>
        {returnQuestion.children?.map((childReturnQuestion) => (
          <ReturnQuestion
            key={childReturnQuestion.id}
            portalHostName={portalHostName}
            returnQuestion={childReturnQuestion}
            returnQuestionParent={returnQuestion}
          />
        ))}
      </>
    </Item>
  );
};

export default memo(ReturnQuestion);
