import { useLayoutEffect } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { CheckoutItemStatus } from "../../domain/checkoutItem/model/checkoutItem";
import { ItemPageViewTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";
import { PROJECT, TrackingPage } from "./tracking";

interface UseTrackItemPageViewFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId?: string;
  readonly checkoutItemId: string;
  readonly productVariantId: string;
  readonly replaceableFor: string[] | undefined;
  readonly status: CheckoutItemStatus;
  readonly unique: boolean;
}

interface UseTrackItemPageViewFunction {
  (agrs: UseTrackItemPageViewFunctionArgs): void;
}

const useTrackItemPageView: UseTrackItemPageViewFunction = ({
  page,
  country,
  segment,
  checkoutId,
  checkoutItemId,
  productVariantId,
  replaceableFor,
  status,
  unique,
}) => {
  const emitUserEvent = useEmitUserEvent<ItemPageViewTrackingEvent>();

  useLayoutEffect(() => {
    if (!(checkoutId && replaceableFor)) {
      return;
    }

    const itemPageViewTrackingEvent: ItemPageViewTrackingEvent = {
      event: TrackingEventName.PAGEVIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${page}`,
      store: country,
      segment,
      checkoutId,
      checkoutItemId,
      status,
      productVariantId,
      replaceableFor: replaceableFor.toString(),
      unique,
    };

    emitUserEvent(itemPageViewTrackingEvent);
  }, [
    checkoutId,
    checkoutItemId,
    country,
    emitUserEvent,
    page,
    productVariantId,
    replaceableFor,
    segment,
    status,
    unique,
  ]);
};

export { useTrackItemPageView };
