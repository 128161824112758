import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { giveCheckoutFeedback as giveCheckoutFeedbackCommand } from "../../../../domain/checkoutFeedback/command/giveCheckoutFeedback";
import { Feedbacks } from "../../../../domain/checkoutFeedback/model/feedbacks";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface GiveCheckoutFeedbackFunctionArgs {
  readonly feedbacks: Feedbacks;
}

interface GiveCheckoutFeedbackFunction {
  (args: GiveCheckoutFeedbackFunctionArgs): Promise<void>;
}

type UseGiveCheckoutFeedback = [giveCheckoutFeedback: GiveCheckoutFeedbackFunction, status: CommandStatus];

interface UseGiveCheckoutFeedbackFunctionArgs {
  readonly checkoutId: string;
  readonly logger: Logger;
}

interface UseGiveCheckoutFeedbackFunction {
  (args: UseGiveCheckoutFeedbackFunctionArgs): UseGiveCheckoutFeedback;
}

const useGiveCheckoutFeedback: UseGiveCheckoutFeedbackFunction = ({ checkoutId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const giveCheckoutFeedback: GiveCheckoutFeedbackFunction = useCallback(
    async ({ feedbacks }) => {
      try {
        await commandBus(
          giveCheckoutFeedbackCommand({
            checkoutId,
            feedbacks,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
        createNotification({
          level: NotificationLevel.ERROR,
          bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
        });
      }
    },
    [checkoutId, commandBus, createNotification, logger],
  );

  return [giveCheckoutFeedback, status];
};

export { useGiveCheckoutFeedback };
