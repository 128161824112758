import React, { FC } from "react";
import { ButtonIcon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages } from "../../../../i18n/i18n";
import { Header } from "../Header";
import { ButtonIconPlaceholder } from "../buttonIconPlaceholder/ButtonIconPlaceholder";
import { style } from "./ItemDetailHeader.style";

interface ItemDetailHeaderProps {
  readonly onBack: () => void;
}
const ItemDetailHeader: FC<ItemDetailHeaderProps> = ({ onBack }) => {
  const title = useI18nMessage({ id: I18nMessages.HEADER_CHECKOUT_TITLE });

  return (
    <Header testID="item-detail-header">
      <ButtonIconPlaceholder />
      <Text level={3} action>
        {title}
      </Text>
      <ButtonIcon name="close" style={style.button} testID="close-button-icon" onPress={onBack} />
    </Header>
  );
};

export { ItemDetailHeader };
