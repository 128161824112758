import { HttpPostFunction } from "@lookiero/sty-psp-http";
import {
  CheckoutBookingByIdView,
  ViewCheckoutBookingByIdResult,
} from "../../../projection/checkoutBooking/viewCheckoutBookingById";

interface HttpCheckoutBookingByIdView extends CheckoutBookingByIdView {}

interface HttpCheckoutBookingByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutBookingByIdViewFunction {
  (args: HttpCheckoutBookingByIdViewFunctionArgs): HttpCheckoutBookingByIdView;
}

interface ViewCheckoutBookingByIdResponse {
  readonly result: ViewCheckoutBookingByIdResult;
}

const httpCheckoutBookingByIdView: HttpCheckoutBookingByIdViewFunction =
  ({ httpPost }) =>
  async ({ checkoutBookingId, signal }) =>
    await httpPost<ViewCheckoutBookingByIdResponse, ViewCheckoutBookingByIdResult>({
      endpoint: "/view-checkout-booking-by-id",
      body: { checkoutBookingId },
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpCheckoutBookingByIdView };
