import { useLayoutEffect } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, PageViewTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface UseTrackPageViewFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}

interface UseTrackPageViewFunction {
  (agrs: UseTrackPageViewFunctionArgs): void;
}

const useTrackPageView: UseTrackPageViewFunction = ({ page, country, segment, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<PageViewTrackingEvent>();

  useLayoutEffect(() => {
    if (!checkoutId) {
      return;
    }

    const pageViewTrackingEvent: PageViewTrackingEvent = {
      event: TrackingEventName.PAGEVIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${page}`,
      store: country,
      segment,
      checkoutId,
    };

    emitUserEvent(pageViewTrackingEvent);
  }, [checkoutId, country, emitUserEvent, page, segment]);
};

export { useTrackPageView };
