import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { listReturnQuestionsByCheckoutItemId } from "../../../../projection/returnQuestion/listReturnQuestionsByCheckoutItemId";
import { ReturnQuestionProjection } from "../../../../projection/returnQuestion/returnQuestion";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseListReturnQuestionsByCheckoutItemIdFunctionArgs {
  readonly checkoutItemId: string;
}

interface UseListReturnQuestionsByCheckoutItemIdFunction {
  (args: UseListReturnQuestionsByCheckoutItemIdFunctionArgs): UseQueryFunctionResult<ReturnQuestionProjection[]>;
}

const useListReturnQuestionsByCheckoutItemId: UseListReturnQuestionsByCheckoutItemIdFunction = ({ checkoutItemId }) =>
  useQuery({
    query: listReturnQuestionsByCheckoutItemId({ checkoutItemId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useListReturnQuestionsByCheckoutItemId };
