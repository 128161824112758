import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { resetCheckoutItem } from "../../../../domain/checkoutItem/command/resetCheckoutItem";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface ResetCheckoutItemFunction {
  (): Promise<void>;
}

interface UseResetCheckoutItemFunctionArgs {
  readonly checkoutItemId: string;
  readonly logger: Logger;
}

type UseResetCheckoutItemResult = [reset: ResetCheckoutItemFunction, status: CommandStatus];

interface UseResetCheckoutItemFunction {
  (args: UseResetCheckoutItemFunctionArgs): UseResetCheckoutItemResult;
}

const useResetCheckoutItem: UseResetCheckoutItemFunction = ({ checkoutItemId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const reset = useCallback(async () => {
    try {
      await commandBus(resetCheckoutItem({ aggregateId: checkoutItemId }));
    } catch (error) {
      logger.captureException(error as Error);
      createNotification({
        level: NotificationLevel.ERROR,
        bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
      });
    }
  }, [checkoutItemId, commandBus, createNotification, logger]);

  return [reset, status];
};

export { useResetCheckoutItem };
