import { Platform } from "react-native";
import { BuildBootstrapFunctionReturn } from "@lookiero/messaging-react";
import { fetchHttpGet, fetchHttpPost } from "@lookiero/sty-psp-http";
import { VERSION } from "../../version";
import { getCheckout, saveCheckout } from "../domain/checkout/model/httpCheckouts";
import { getCheckoutBooking, saveCheckoutBooking } from "../domain/checkoutBooking/model/httpCheckoutBookings";
import { getCheckoutFeedback, saveCheckoutFeedback } from "../domain/checkoutFeedback/model/httpCheckoutFeedbacks";
import { getCheckoutItem, saveCheckoutItem } from "../domain/checkoutItem/model/httpCheckoutItems";
import { getUiSetting, saveUiSetting } from "../domain/uiSetting/model/storageUiSettings";
import { read, write } from "../persistence/asyncStorageStorage";
import { httpBookedProductsVariantsForCheckoutItemView } from "../projection/bookedProductsVariants/httpBookedProductsVariantsForCheckoutItemView";
import { httpCheckoutByIdView } from "../projection/checkout/httpCheckoutByIdView";
import { httpFirstAvailableCheckoutByCustomerIdView } from "../projection/checkout/httpFirstAvailableCheckoutByCustomerIdView";
import { httpFiveItemsDiscountByCustomerIdView } from "../projection/checkout/httpFiveItemsDiscountByCustomerIdView";
import { httpIsCheckoutEnabledByCustomerIdView } from "../projection/checkout/httpIsCheckoutEnabledByCustomerIdView";
import { httpIsSizeChangeEnabledByCheckoutIdView } from "../projection/checkout/httpIsSizeChangeEnabledByCheckoutIdView";
import { httpCheckoutBookingByIdView } from "../projection/checkoutBooking/httpCheckoutBookingByIdView";
import { httpCheckoutItemByIdView } from "../projection/checkoutItem/httpCheckoutItemByIdView";
import { httpCheckoutQuestionsByCheckoutIdView } from "../projection/checkoutQuestion/httpCheckoutQuestionsByCheckoutIdView";
import { httpPaymentFlowPayloadByCheckoutIdView } from "../projection/payment/httpPaymentFlowPayloadByCheckoutIdView";
import { httpPricingByCheckoutIdView } from "../projection/pricing/httpPricingByCheckoutIdView";
import { httpReturnQuestionsByCheckoutItemIdView } from "../projection/returnQuestion/httpReturnQuestionsByCheckoutItemIdView";
import { storageUiSettingByKeyView } from "../projection/uiSetting/storageUiSettingByKeyView";
import { baseBootstrap } from "./baseBootstrap";

type OS = typeof Platform.OS;
type Device = Exclude<OS, "macos" | "windows">;
const device = Platform.OS as Device;

interface BootstrapFunctionArgs {
  readonly getAuthToken: () => Promise<string>;
  readonly apiUrl: () => string;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): BuildBootstrapFunctionReturn;
}

const bootstrap: BootstrapFunction = ({ apiUrl, getAuthToken }) => {
  const httpGet = fetchHttpGet({ apiUrl, getAuthToken, device, version: VERSION });
  const httpPost = fetchHttpPost({ apiUrl, getAuthToken, device, version: VERSION });

  return baseBootstrap({
    checkoutByIdView: httpCheckoutByIdView({ httpPost }),
    firstAvailableCheckoutByCustomerIdView: httpFirstAvailableCheckoutByCustomerIdView({ httpPost }),
    isCheckoutEnabledByCustomerIdView: httpIsCheckoutEnabledByCustomerIdView({ httpGet }),
    isSizeChangeEnabledByCheckoutIdView: httpIsSizeChangeEnabledByCheckoutIdView({ httpGet }),
    fiveItemsDiscountByCustomerIdView: httpFiveItemsDiscountByCustomerIdView({ httpGet }),
    uiSettingByKeyView: storageUiSettingByKeyView({ read }),
    checkoutItemByIdView: httpCheckoutItemByIdView({ httpPost }),
    returnQuestionsByCheckoutItemIdView: httpReturnQuestionsByCheckoutItemIdView({ httpPost }),
    checkoutBookingByIdView: httpCheckoutBookingByIdView({ httpPost }),
    bookedProductsVariantsForCheckoutItemView: httpBookedProductsVariantsForCheckoutItemView({
      httpPost,
    }),
    pricingByCheckoutIdView: httpPricingByCheckoutIdView({
      httpPost,
    }),
    paymentFlowPayloadByCheckoutIdView: httpPaymentFlowPayloadByCheckoutIdView({
      httpPost,
    }),
    checkoutQuestionsByCheckoutIdView: httpCheckoutQuestionsByCheckoutIdView({
      httpPost,
    }),
    getUiSetting,
    saveUiSetting,
    uiSettingsDependencies: [{ read, write }],
    getCheckout,
    saveCheckout,
    checkoutsDependencies: [{ httpPost }],
    getCheckoutItem,
    saveCheckoutItem,
    checkoutItemsDependencies: [{ httpPost }],
    getCheckoutBooking,
    saveCheckoutBooking,
    checkoutBookingsDependencies: [{ httpPost }],
    getCheckoutFeedback,
    saveCheckoutFeedback,
    checkoutFeedbacksDependencies: [{ httpPost }],
  });
};

export { bootstrap };
