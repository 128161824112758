import React, { createContext, FC, ReactNode, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { Customer } from "../../../projection/customer/customer";
import { KameleoonEnvironment } from "../../ab-testing/kameleoonEnvironment";

interface StaticInfo {
  readonly kameleoon: KameleoonEnvironment;
  readonly customer: Customer;
}

const StaticInfoContext = createContext<StaticInfo>(null as unknown as StaticInfo);

interface StaticInfoProviderProps {
  readonly children: ReactNode;
  readonly kameleoon: KameleoonEnvironment;
  readonly customer: Customer;
}

const StaticInfoProvider: FC<StaticInfoProviderProps> = ({ children, kameleoon, customer }) => {
  const value = useMemo(() => ({ kameleoon, customer }), [customer, kameleoon]);

  return <StaticInfoContext.Provider value={value}>{children}</StaticInfoContext.Provider>;
};

const useStaticInfo = () => {
  const staticInfo = useContext(StaticInfoContext);

  invariant(
    staticInfo,
    "Your are trying to use the useStaticInfo hook without wrapping your app with the <StaticInfoProvider>.",
  );

  return staticInfo;
};

export { useStaticInfo, StaticInfoProvider };
