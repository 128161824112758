import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, PressBackTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface PressBackFunction {
  (): void;
}

interface UseTrackPressBackFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}

interface UseTrackPressBackFunction {
  (args: UseTrackPressBackFunctionArgs): PressBackFunction;
}

const useTrackPressBack: UseTrackPressBackFunction = ({ page, country, segment, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<PressBackTrackingEvent>();

  const pressBack: PressBackFunction = useCallback(() => {
    if (!checkoutId) {
      return;
    }

    const pressBackTrackingEvent: PressBackTrackingEvent = {
      event: TrackingEventName.PRESS_BACK,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${page}`,
      store: country,
      segment,
      checkoutId,
    };

    emitUserEvent(pressBackTrackingEvent);
  }, [checkoutId, country, emitUserEvent, page, segment]);

  return pressBack;
};

export { useTrackPressBack };
