import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, TabViewTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface TabViewFunctionArgs<T> {
  readonly tab: T;
}
interface TabViewFunction<T> {
  (args: TabViewFunctionArgs<T>): void;
}

interface UseTrackTabViewFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
}

const useTrackTabView = <T>({
  page,
  country,
  segment,
  checkoutId,
}: UseTrackTabViewFunctionArgs): TabViewFunction<T> => {
  const emitUserEvent = useEmitUserEvent<TabViewTrackingEvent<T>>();

  const tabView: TabViewFunction<T> = useCallback(
    ({ tab }) => {
      if (!checkoutId) {
        return;
      }

      const tabViewTrackingEvent: TabViewTrackingEvent<T> = {
        event: TrackingEventName.TAB_VIEW,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        tab,
      };

      emitUserEvent(tabViewTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, page, segment],
  );

  return tabView;
};

export { useTrackTabView };
