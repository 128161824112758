import React, { FC } from "react";
import { View } from "react-native";
import { Button, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Modal } from "@lookiero/sty-psp-ui";
import { I18nMessages } from "../../../../i18n/i18n";
import { style } from "./SizeWithoutStockModal.style";

interface SizeWithoutStockModalProps {
  readonly visible: boolean;
  readonly onDismiss: () => void;
}
const SizeWithoutStockModal: FC<SizeWithoutStockModalProps> = ({ visible, onDismiss }) => {
  const titleText = useI18nMessage({ id: I18nMessages.SIZE_WITHOUT_STOCK_MODAL_TITLE });
  const descriptionText = useI18nMessage({ id: I18nMessages.SIZE_WITHOUT_STOCK_MODAL_DESCRIPTION });
  const buttonText = useI18nMessage({ id: I18nMessages.SIZE_WITHOUT_STOCK_MODAL_BUTTON });

  return (
    <Modal visible={visible} showCloseButton onClose={onDismiss}>
      <View style={style.modalContent}>
        <Text level={3} style={style.title} heading>
          {titleText}
        </Text>
        <Text level={3} style={style.description}>
          {descriptionText}
        </Text>
        <Button onPress={onDismiss}>{buttonText}</Button>
      </View>
    </Modal>
  );
};

export { SizeWithoutStockModal };
