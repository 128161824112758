import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, space4, space6 } = theme();

const style = StyleSheet.create({
  title: {
    backgroundColor: colorBgBase,
    paddingBottom: space4,
    paddingHorizontal: space6,
    paddingTop: space6,
  },
});

export { style };
