import React, { FC, ReactNode, useCallback, useEffect } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { ProductVariantProjection } from "../../../../../../projection/bookedProductsVariants/bookedProductsVariants";
import {
  CheckoutItemProjection,
  MediaPerspective,
  MediaProjection,
} from "../../../../../../projection/checkoutItem/checkoutItem";
import { TrackingPage } from "../../../../../tracking/tracking";
import { useTrackImageView } from "../../../../../tracking/useTrackImageView";
import { ProductVariantDescription } from "../../components/productVariantDescription/ProductVariantDescription";
import { ProductVariantSlider } from "../../components/productVariantSlider/ProductVariantSlider";
import { style } from "./ProductVariant.style";

type ProductVariantStyle = "content";

interface ProductVariantProps {
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string;
  readonly checkoutItem: CheckoutItemProjection;
  readonly currentProductVariant: ProductVariantProjection;
  readonly style?: Partial<Record<ProductVariantStyle, StyleProp<ViewStyle>>>;
  readonly children?: ReactNode;
  readonly customerDecissionBanner?: ReactNode;
}

const ProductVariant: FC<ProductVariantProps> = ({
  country,
  segment,
  checkoutId,
  checkoutItem,
  currentProductVariant,
  style: customStyle,
  children,
  customerDecissionBanner,
}) => {
  const screenSize = useScreenSize();
  const isDesktopScreen = screenSize !== "S";

  const trackImageView = useTrackImageView({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId,
    checkoutItemId: checkoutItem.id,
    productVariantId: checkoutItem.productVariant.id,
  });
  useEffect(
    () =>
      trackImageView({
        perspective: checkoutItem.productVariant.media[0]?.perspective as MediaPerspective,
      }),
    [checkoutItem.productVariant.media, trackImageView],
  );
  const handleOnChangedProductVariantSlider = useCallback(
    (active: number) =>
      trackImageView({ perspective: checkoutItem.productVariant.media[active]?.perspective as MediaPerspective }),
    [checkoutItem.productVariant.media, trackImageView],
  );

  const { price } = checkoutItem;
  const { media, brand, name } = checkoutItem.productVariant;
  const collage = media.find((m) => m.perspective === MediaPerspective.COLLAGE) as MediaProjection;

  return (
    <View style={[style.container, isDesktopScreen ? style.largeContainer : null]} testID="product-variant">
      <ProductVariantSlider
        producVariantMedia={collage ? [...media, collage] : media}
        onChanged={handleOnChangedProductVariantSlider}
      />

      <View style={[style.content, customStyle?.content]}>
        {customerDecissionBanner}
        <ProductVariantDescription
          brand={brand}
          country={country}
          name={name}
          price={price}
          size={currentProductVariant.size}
        />

        {children}
      </View>
    </View>
  );
};

export { ProductVariant };
