import React, { FC } from "react";
import { Pressable, View } from "react-native";
import { InfoBox, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { CheckoutItemStatus } from "../../../../../../domain/checkoutItem/model/checkoutItem";
import { I18nMessages } from "../../../../i18n/i18n";
import { style } from "./CustomerDecissionBanner.style";

type CustomerDecissionBannerStatus = Exclude<CheckoutItemStatus, CheckoutItemStatus.INITIAL>;

const i18nMessageForCheckoutItemStatus: Record<CustomerDecissionBannerStatus, string> = {
  [CheckoutItemStatus.KEPT]: I18nMessages.ITEM_BANNER_CUSTOMER_KEPT_DECISSION,
  [CheckoutItemStatus.REPLACED]: I18nMessages.ITEM_BANNER_CUSTOMER_REPLACED_DECISSION,
  [CheckoutItemStatus.RETURNED]: I18nMessages.ITEM_BANNER_CUSTOMER_RETURNED_DECISSION,
};

interface CustomerDecissionBannerProps {
  readonly checkoutItemStatus: CustomerDecissionBannerStatus;
  readonly onPress: () => void;
}
const CustomerDecissionBanner: FC<CustomerDecissionBannerProps> = ({ checkoutItemStatus, onPress }) => {
  const decissionText = useI18nMessage({ id: i18nMessageForCheckoutItemStatus[checkoutItemStatus] });
  const bannerButtonText = useI18nMessage({ id: I18nMessages.ITEM_BANNER_BUTTON });
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize === "S";

  return (
    <View style={[style.container, isSmallScreen ? style.smallContainer : null]}>
      <InfoBox text={decissionText}>
        <Pressable testID="customer-decission-banner" onPress={onPress}>
          <Text level={isSmallScreen ? 2 : 1} detail underlined>
            {bannerButtonText}
          </Text>
        </Pressable>
      </InfoBox>
    </View>
  );
};

export type { CustomerDecissionBannerStatus };
export { CustomerDecissionBanner };
