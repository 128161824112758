import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingPage, ReplaceItemTrackingEvent, TrackingEventName, TrackingEventCategory } from "./tracking";

interface ReplaceItemFunctionArgs {
  readonly replaceFor: string;
}
interface ReplaceItemFunction {
  (args: ReplaceItemFunctionArgs): void;
}

interface UseTrackReplaceItemFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly checkoutId: string | undefined;
  readonly checkoutItemId: string;
  readonly productVariantId: string;
}
interface UseTrackReplaceItemFunction {
  (agrs: UseTrackReplaceItemFunctionArgs): ReplaceItemFunction;
}

const useTrackReplaceItem: UseTrackReplaceItemFunction = ({
  page,
  country,
  segment,
  checkoutId,
  checkoutItemId,
  productVariantId,
}) => {
  const emitUserEvent = useEmitUserEvent<ReplaceItemTrackingEvent>();

  const replaceItem: ReplaceItemFunction = useCallback(
    ({ replaceFor }) => {
      if (!checkoutId) {
        return;
      }

      const replaceItemTrackingEvent: ReplaceItemTrackingEvent = {
        event: TrackingEventName.REPLACE_ITEM,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
        checkoutId,
        checkoutItemId,
        productVariantId,
        replaceFor,
      };

      emitUserEvent(replaceItemTrackingEvent);
    },
    [checkoutId, checkoutItemId, country, emitUserEvent, page, productVariantId, segment],
  );

  return replaceItem;
};

export { useTrackReplaceItem };
