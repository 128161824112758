import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, space2, space6 } = theme();

const style = StyleSheet.create({
  title: {
    marginBottom: space2,
  },
  wrapper: {
    backgroundColor: colorBgBase,
    marginBottom: space2,
    padding: space6,
  },
});

export { style };
