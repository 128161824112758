import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderRadius2, borderWidth1, colorBgBase, colorBorderInput, space2, space4 } = theme();

const IMAGE_SIZE = 35;

const style = StyleSheet.create({
  content: {
    flexDirection: "row",
  },
  image: {
    borderRadius: borderRadius2,
    width: IMAGE_SIZE,
  },
  info: {
    flex: 1,
    marginLeft: space2,
  },
  mobileWrapper: {
    borderBottomWidth: borderWidth1,
    borderColor: colorBorderInput,
  },
  price: {
    justifyContent: "flex-end",
  },
  row: {
    flex: 1,
    justifyContent: "center",
  },
  wrapper: {
    backgroundColor: colorBgBase,
    flexDirection: "row",
    padding: space4,
  },
});

export { style, IMAGE_SIZE };
