import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space4, space6 } = theme();

const style = StyleSheet.create({
  container: {
    marginBottom: space4,
  },
  smallContainer: {
    marginHorizontal: space6,
  },
});

export { style };
