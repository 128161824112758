import { HttpPostFunction } from "@lookiero/sty-psp-http";
import {
  CheckoutItemByIdView,
  ViewCheckoutItemByIdResult,
} from "../../../projection/checkoutItem/viewCheckoutItemById";

interface HttpCheckoutItemByIdView extends CheckoutItemByIdView {}

interface HttpCheckoutItemByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutItemByIdViewFunction {
  (args: HttpCheckoutItemByIdViewFunctionArgs): HttpCheckoutItemByIdView;
}

interface ViewCheckoutItemByIdResponse {
  readonly result: ViewCheckoutItemByIdResult;
}

const httpCheckoutItemByIdView: HttpCheckoutItemByIdViewFunction =
  ({ httpPost }) =>
  async ({ checkoutItemId, signal }) =>
    await httpPost<ViewCheckoutItemByIdResponse, ViewCheckoutItemByIdResult>({
      endpoint: "/view-checkout-item-by-id",
      body: { checkoutItemId },
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpCheckoutItemByIdView };
