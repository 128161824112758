import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
import { HEADER_HEIGHT } from "../../../../components/templates/header/Header.style";

const { borderRadius5, colorBgPrimaryLight, colorBgBase, colorTextMedium, space4, space6, space8 } = theme();

const style = StyleSheet.create({
  background: {
    backgroundColor: colorBgPrimaryLight,
  },
  description: {
    color: colorTextMedium,
  },
  desktopInfo: {
    borderTopLeftRadius: borderRadius5,
    borderTopRightRadius: borderRadius5,
  },
  desktopLayoutSpacing: {
    paddingVertical: space8,
  },
  header: {
    height: HEADER_HEIGHT,
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  info: {
    backgroundColor: colorBgBase,
    paddingBottom: space4,
    paddingHorizontal: space6,
    paddingTop: space8,
  },
  layout: {
    flex: 1,
    justifyContent: "center",
  },
  safeAreaView: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  submit: {
    backgroundColor: colorBgBase,
    padding: space6,
  },
  submitDesktop: {
    borderBottomLeftRadius: borderRadius5,
    borderBottomRightRadius: borderRadius5,
  },
});

export { style };
