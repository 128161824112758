import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import {
  FiveItemsDiscountByCustomerIdProjection,
  viewFiveItemsDiscountByCustomerId,
} from "../../../../projection/checkout/viewFiveItemsDiscountByCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewFiveItemsDiscountByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseViewFiveItemsDiscountByCustomerIdFunction {
  (
    args: UseViewFiveItemsDiscountByCustomerIdFunctionArgs,
  ): UseQueryFunctionResult<FiveItemsDiscountByCustomerIdProjection>;
}

const useViewFiveItemsDiscountByCustomerId: UseViewFiveItemsDiscountByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewFiveItemsDiscountByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: Infinity },
  });

export { useViewFiveItemsDiscountByCustomerId };
