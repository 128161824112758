import React, { FC, useCallback } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { generatePath, useNavigate } from "react-router-native";
import { ButtonIcon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CheckoutItemProjection } from "../../../../../../projection/checkoutItem/checkoutItem";
import { I18nMessages } from "../../../../i18n/i18n";
import { Routes } from "../../../../routing/routes";
import { useBasePath } from "../../../../routing/useBasePath";
import { Header } from "../Header";
import { ButtonIconPlaceholder } from "../buttonIconPlaceholder/ButtonIconPlaceholder";
import style from "./ItemHeader.style";

interface OnNextItemFunctionArgs {
  readonly from: string;
  readonly to: string;
}
interface OnNextItemFunction {
  (args: OnNextItemFunctionArgs): void;
}
interface OnPreviousItemFunctionArgs {
  readonly from: string;
  readonly to: string;
}
interface OnPreviousItemFunction {
  (args: OnPreviousItemFunctionArgs): void;
}

interface ItemHeaderProps {
  readonly item: CheckoutItemProjection;
  readonly items: CheckoutItemProjection[];
  readonly style?: StyleProp<ViewStyle>;
  readonly onNext?: OnNextItemFunction;
  readonly onPrevious?: OnPreviousItemFunction;
}
const ItemHeader: FC<ItemHeaderProps> = ({ items, item, onNext, onPrevious }) => {
  const title = useI18nMessage({ id: I18nMessages.HEADER_CHECKOUT_TITLE });
  const itemIndex = items.indexOf(item);
  const isFirstItem = itemIndex === 0;
  const isLastItem = itemIndex === items.length - 1;
  const basePath = useBasePath();
  const navigate = useNavigate();

  const navigateToPreviousItem = useCallback(() => {
    const previousItemIndex = itemIndex === 0 ? items.length - 1 : itemIndex - 1;
    const previousItem = items[previousItemIndex] as CheckoutItemProjection;

    onPrevious?.({ from: item.id, to: previousItem.id });
    navigate(generatePath(`${basePath}/${Routes.ITEM}`, { id: previousItem.id }));
  }, [basePath, item.id, itemIndex, items, navigate, onPrevious]);

  const navigateToNextItem = useCallback(() => {
    const nextItemIndex = itemIndex === items.length - 1 ? 0 : itemIndex + 1;
    const nextItem = items[nextItemIndex] as CheckoutItemProjection;

    onNext?.({ from: item.id, to: nextItem.id });
    navigate(generatePath(`${basePath}/${Routes.ITEM}`, { id: nextItem.id }));
  }, [basePath, item.id, itemIndex, items, navigate, onNext]);

  return (
    <Header testID="item-header">
      {!isFirstItem ? (
        <ButtonIcon
          name="arrow_left"
          style={style.button}
          testID="arrow-left-button-icon"
          onPress={navigateToPreviousItem}
        />
      ) : (
        <ButtonIconPlaceholder />
      )}
      <Text level={3} action>
        {title}
      </Text>
      {!isLastItem ? (
        <ButtonIcon
          name="arrow_right"
          style={style.button}
          testID="arrow-right-button-icon"
          onPress={navigateToNextItem}
        />
      ) : (
        <ButtonIconPlaceholder />
      )}
    </Header>
  );
};

export { ItemHeader };
