import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderWidth1, colorBorderInput, space6 } = theme();

const style = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    paddingBottom: space6,
    paddingHorizontal: 0,
  },
  feedbackContainer: {
    borderTopColor: colorBorderInput,
    borderTopWidth: borderWidth1,
    marginTop: space6,
    paddingTop: space6,
  },
  feedbackContainerMobile: {
    paddingHorizontal: space6,
  },
});

export { style };
