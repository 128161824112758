import { StyleSheet } from "react-native";

// TODO: get the font family from Aurora

const style = StyleSheet.create({
  textBold: {
    fontFamily: "AreaNormal-Extrabold",
  },
});

export { style };
