import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { bookCheckoutBookingForCheckoutItem } from "../../../../domain/checkoutBooking/command/bookCheckoutBookingForCheckoutItem";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface BookFunction {
  (): Promise<void>;
}

type UseBookCheckoutBookingForCheckoutItem = [book: BookFunction, status: CommandStatus];

interface UseBookCheckoutBookingForCheckoutItemFunctionArgs {
  readonly checkoutItemId: string;
  readonly checkoutBookingId: string | undefined;
  readonly logger: Logger;
}

interface UseBookCheckoutBookingForCheckoutItemFunction {
  (args: UseBookCheckoutBookingForCheckoutItemFunctionArgs): UseBookCheckoutBookingForCheckoutItem;
}

const useBookCheckoutBookingForCheckoutItem: UseBookCheckoutBookingForCheckoutItemFunction = ({
  checkoutItemId,
  checkoutBookingId,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const book = useCallback(async () => {
    try {
      await commandBus(
        bookCheckoutBookingForCheckoutItem({
          aggregateId: checkoutBookingId || uuid(),
          checkoutItemId,
        }),
      );
    } catch (error) {
      logger.captureException(error as Error);
      createNotification({
        level: NotificationLevel.ERROR,
        bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
      });
    }
  }, [checkoutBookingId, checkoutItemId, commandBus, createNotification, logger]);

  return [book, status];
};

export { useBookCheckoutBookingForCheckoutItem };
