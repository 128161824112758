import React, { FC } from "react";
import { useParams } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import { QueryStatus } from "@lookiero/messaging-react";
import { Layout as UiLayout } from "@lookiero/sty-psp-ui";
import { CheckoutItemProjection } from "../../../../projection/checkoutItem/checkoutItem";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { ReturnQuestionFeedbackProvider } from "../../components/organisms/returnQuestions/behaviors/useReturnQuestionFeedback";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { ReturnQuestionsForm } from "./components/returnQuestionsForm/ReturnQuestionsForm";

interface ReturnProps {
  readonly layout: UiLayout;
}

const Return: FC<ReturnProps> = ({ layout }) => {
  const { id } = useParams();
  const {
    customer: { customerId, country, segment },
  } = useStaticInfo();

  const [checkout, checkoutStatus] = useViewFirstAvailableCheckoutByCustomerId({ customerId });

  const checkoutItem = checkout?.items.find((checkoutItem) => checkoutItem.id === id) as CheckoutItemProjection;

  const dependenciesLoaded = checkoutStatus !== QueryStatus.LOADING && checkout && checkoutItem;

  if (!dependenciesLoaded) {
    return <Spinner />;
  }

  return (
    <ReturnQuestionFeedbackProvider key={checkoutItem.id} feedback={checkoutItem.feedbacks || {}}>
      <ReturnQuestionsForm
        checkout={checkout}
        checkoutItem={checkoutItem}
        country={country}
        layout={layout}
        segment={segment}
      />
    </ReturnQuestionFeedbackProvider>
  );
};

export { Return };
