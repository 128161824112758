import React, { useCallback } from "react";
import { Button, BUTTON_VARIANT } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useCheckoutQuestionFeedbackForId } from "../../behaviors/useCheckoutQuestionFeedback";
import { CheckoutQuestionItem, CheckoutQuestionItemProps } from "../CheckoutQuestionItem";
import { style } from "./ButtonCheckoutQuestionItem.style";

const ButtonCheckoutQuestionItem: CheckoutQuestionItem = ({
  checkoutQuestion,
  checkoutQuestionParentId,
  testID,
}: CheckoutQuestionItemProps) => {
  const { feedback, onChange } = useCheckoutQuestionFeedbackForId({ id: checkoutQuestionParentId });

  const optionText = useI18nMessage({ id: checkoutQuestion.name });
  const handleOnPress = useCallback(
    () => onChange({ checkoutQuestionId: checkoutQuestionParentId, checkoutQuestionFeedback: checkoutQuestion.id }),
    [onChange, checkoutQuestion.id, checkoutQuestionParentId],
  );

  return (
    <Button
      accessibilityLabel={testID}
      style={style.button}
      testID={testID}
      variant={feedback === checkoutQuestion.id ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
      onPress={handleOnPress}
    >
      {optionText}
    </Button>
  );
};

export { ButtonCheckoutQuestionItem };
