import { animated, useSpring } from "@react-spring/native";
import React, { FC, useCallback, useState } from "react";
import { LayoutChangeEvent, StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { Text } from "@lookiero/aurora";
import { theme } from "@lookiero/sty-psp-ui";
import { style } from "./Field.style";

const SCALE = 0.75;

const { space1, space4 } = theme();

type FieldStyle = {
  readonly field: StyleProp<ViewStyle>;
  readonly fieldText: StyleProp<TextStyle>;
};

interface FieldProps {
  readonly label: string;
  readonly isFocused?: boolean;
  readonly style?: FieldStyle;
}
const Field: FC<FieldProps> = ({ label, isFocused = false, style: customStyle }) => {
  const [width, setWidth] = useState<number>(0);
  const handleOnLayout = useCallback(({ nativeEvent: { layout } }: LayoutChangeEvent) => setWidth(layout.width), []);

  const springs = useSpring(
    isFocused
      ? { scale: SCALE, translateY: -14, translateX: -(((1 - SCALE) * width) / 2 + space1) }
      : { scale: 1, translateY: space4, translateX: 0 },
  );

  return (
    <animated.View
      pointerEvents="none"
      style={[
        {
          transform: [{ scale: springs.scale }, { translateY: springs.translateY }, { translateX: springs.translateX }],
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customStyle?.field,
      ]}
      onLayout={handleOnLayout}
    >
      <Text level={1} numberOfLines={1} style={[style.fieldText, customStyle?.fieldText]} detail>
        {label}
      </Text>
      <View style={[style.fieldBackground, { opacity: isFocused ? 1 : 0 }]} />
    </animated.View>
  );
};

export type { FieldStyle };
export { Field };
