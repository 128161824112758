import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { CheckoutQuestionProjection } from "../../../../projection/checkoutQuestion/checkoutQuestion";
import { listCheckoutQuestionsByCheckoutId } from "../../../../projection/checkoutQuestion/listCheckoutQuestionsByCheckoutId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseListChckoutQuestionsByCheckoutIdFunctionArgs {
  readonly checkoutId: string;
}

interface UseListChckoutQuestionsByCheckoutIdFunction {
  (args: UseListChckoutQuestionsByCheckoutIdFunctionArgs): UseQueryFunctionResult<CheckoutQuestionProjection[]>;
}

const useListCheckoutQuestionsByCheckoutId: UseListChckoutQuestionsByCheckoutIdFunction = ({ checkoutId }) =>
  useQuery({
    query: listCheckoutQuestionsByCheckoutId({ checkoutId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useListCheckoutQuestionsByCheckoutId };
