import React, { FC, useCallback, useState } from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { COLOR, Icon, IconName, Input, InputProperties } from "@lookiero/aurora";
import { Error, ErrorStyle, theme } from "@lookiero/sty-psp-ui";
import { Field, FieldStyle } from "../../atoms/field/Field";
import { style } from "./InputField.style";

const {
  colorBgBase,
  colorBorderInput,
  colorBorderInputError,
  colorBorderInputFocus,
  colorText,
  colorTextError,
  colorTextMedium,
  iconSize,
  space6,
} = theme();

const inputPaddingRightWithIcon = iconSize + space6;

type IconStyle = StyleProp<TextStyle>;

type InputFieldStyle = FieldStyle & {
  readonly inputField: StyleProp<ViewStyle>;
  readonly error: ErrorStyle;
  readonly icon: IconStyle;
};

interface InputFieldBaseProps {
  readonly label: string;
  readonly multiline?: boolean;
  readonly error?: string | null;
  readonly style?: Partial<InputFieldStyle>;
  readonly onChange?: (value: string) => void;
  readonly icon?: IconName;
}

interface InputFieldProps extends Omit<InputProperties, keyof InputFieldBaseProps>, InputFieldBaseProps {}

const InputField: FC<InputFieldProps> = ({
  label,
  placeholder,
  value,
  multiline = false,
  error,
  style: customStyle,
  onChange = () => void 0,
  icon,
  testID = "input",
  ...inputRestProps
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleOnChange = useCallback((text: string) => onChange(text), [onChange]);

  const handleOnBlur = useCallback(() => setIsFocused(false), []);

  const handleOnFocus = useCallback(() => setIsFocused(true), []);

  const borderColor = error ? colorBorderInputError : isFocused ? colorBorderInputFocus : colorBorderInput;
  const color = error ? colorTextError : isFocused ? colorText : colorTextMedium;

  return (
    <View style={[style.inputField, { borderColor }, customStyle?.inputField]} testID="input-field">
      <Field
        isFocused={isFocused || !!value}
        label={label}
        style={{
          field: [style.field, customStyle?.field],
          fieldText: [{ color }, customStyle?.fieldText],
        }}
      />
      <Input
        autoCapitalize="sentences"
        multiline={multiline}
        multilineMaxHeight={242}
        placeholder={placeholder}
        placeholderTextColor={isFocused ? colorTextMedium : colorBgBase}
        style={[!!icon && { paddingRight: inputPaddingRightWithIcon }, style.input, { color }]}
        testID={testID}
        value={value}
        onBlur={handleOnBlur}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        {...inputRestProps}
      />
      {!!icon && <Icon color={COLOR.GRAYSCALE_L} name={icon} style={[style.icon, customStyle?.icon]} testID="icon" />}
      {!!error && <Error error={error} style={[style.error, customStyle?.error]} />}
    </View>
  );
};

export type { InputFieldStyle };
export { InputField };
