import React, { FC, ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Column, Row } from "@lookiero/sty-psp-ui";
import { style } from "./Body.style";

type BodyStyle = "row" | "column";

interface BodyProps {
  readonly children: ReactNode;
  readonly style?: Partial<Record<BodyStyle, StyleProp<ViewStyle>>>;
}

const Body: FC<BodyProps> = ({ children, style: customStyle }) => (
  <Row style={[style.row, customStyle?.row]}>
    <Column style={customStyle?.column}>{children}</Column>
  </Row>
);

export { Body };
