import { HttpPostFunction } from "@lookiero/sty-psp-http";
import {
  ListReturnQuestionsByCheckoutItemIdResult,
  ReturnQuestionsByCheckoutItemIdView,
} from "../../../projection/returnQuestion/listReturnQuestionsByCheckoutItemId";

interface HttpReturnQuestionsByCheckoutItemIdView extends ReturnQuestionsByCheckoutItemIdView {}

interface HttpReturnQuestionsByCheckoutItemIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpReturnQuestionsByCheckoutItemIdViewFunction {
  (args: HttpReturnQuestionsByCheckoutItemIdViewFunctionArgs): HttpReturnQuestionsByCheckoutItemIdView;
}

interface ListReturnQuestionsByCheckoutItemIdResponse {
  readonly result: ListReturnQuestionsByCheckoutItemIdResult;
}

const httpReturnQuestionsByCheckoutItemIdView: HttpReturnQuestionsByCheckoutItemIdViewFunction =
  ({ httpPost }) =>
  async ({ checkoutItemId, signal }) =>
    await httpPost<ListReturnQuestionsByCheckoutItemIdResponse, ListReturnQuestionsByCheckoutItemIdResult>({
      endpoint: "/list-return-questions-by-checkout-item-id",
      body: { checkoutItemId },
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpReturnQuestionsByCheckoutItemIdView };
