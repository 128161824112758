import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { replaceCheckoutItem } from "../../../../domain/checkoutItem/command/replaceCheckoutItem";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface ReplaceCheckoutItemFunctionArgs {
  readonly replacedForId: string;
}

interface ReplaceCheckoutItemFunction {
  (args: ReplaceCheckoutItemFunctionArgs): Promise<void>;
}

interface UseReplaceCheckoutItemFunctionArgs {
  readonly checkoutItemId: string;
  readonly logger: Logger;
}

type UseReplaceCheckoutItemResult = [replace: ReplaceCheckoutItemFunction, status: CommandStatus];

interface UseReplaceCheckoutItemFunction {
  (args: UseReplaceCheckoutItemFunctionArgs): UseReplaceCheckoutItemResult;
}

const useReplaceCheckoutItem: UseReplaceCheckoutItemFunction = ({ checkoutItemId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const replace: ReplaceCheckoutItemFunction = useCallback(
    async ({ replacedForId }) => {
      try {
        await commandBus(replaceCheckoutItem({ aggregateId: checkoutItemId, replacedForId }));
      } catch (error) {
        logger.captureException(error as Error);
        createNotification({
          level: NotificationLevel.ERROR,
          bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
        });
      }
    },
    [checkoutItemId, commandBus, createNotification, logger],
  );

  return [replace, status];
};

export { useReplaceCheckoutItem };
