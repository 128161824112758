import { useFonts } from "expo-font";
import "expo/build/Expo.fx";
import React, { FC, useCallback, useState } from "react";
import { Platform, ScrollView } from "react-native";
import "react-native-get-random-values";
import { Navigate, Route, Routes } from "react-router-native";
import { Aurora, Text } from "@lookiero/aurora";
import { EventProvider } from "@lookiero/event";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import { PaymentsQueryProvider, setPaymentsBridge } from "@lookiero/payments-front";
import { fetchTranslations, translationExternalEndpoint } from "@lookiero/sty-psp-i18n";
import { Country, Locale } from "@lookiero/sty-psp-locale";
import { SentryEnvironment } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { DummyLayout } from "@lookiero/sty-psp-ui";
import { KameleoonEnvironment } from "./infrastructure/ab-testing/kameleoonEnvironment";
// import { bootstrap as checkoutMockBootstrap } from "./infrastructure/delivery/bootstrap.mock";
import { bootstrap as checkoutBootstrap } from "./infrastructure/delivery/bootstrap";
import { root } from "./infrastructure/ui/Root";
import { Router } from "./infrastructure/ui/routing/router/Router";
import { Customer } from "./projection/customer/customer";
import { Order } from "./projection/order/order";
import { Subscription } from "./projection/subscription/subscription";
import { VERSION } from "./version";

const locale: Locale = Locale.es_ES;
const subscription: Subscription = "b";
const order: Order = {
  isFirstOrder: false,
  orderNumber: 3687582,
  coupon: "MYLOOKIERO",
};

const customer: Customer = {
  customerId: "caa609aa-437c-487a-b2f5-f8037fdfb029",
  country: Country.ES,
  segment: Segment.WOMEN,
};

const sentryConfig: SentryEnvironment = {
  publicKey: "66cadf9444db4ea5945670f12ec08ae7",
  release: VERSION,
  project: "4504400729276416",
  environment: `${Platform.OS}-EXPO`,
};

const apiUrl =
  Platform.OS !== "web" ? "https://web2.sp.dev.aws.lookiero.es/quiz/api" : __DEV__ ? "/local-to-dev" : "/checkout/api";
const authToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjUzNzc0NzMsImV4cCI6MTcyNzUyMDA0NywiZGlzcGxheU5hbWUiOiJBbGV4YW5kZXIiLCJjb3VudHJ5X2NvZGUiOiJFUyIsImFjY2Vzc1ZpYSI6ImVtYWlsIiwic3Vic2NyaXB0aW9uU3RhcnRpbmdEYXRlIjoiMjAyNC0wOC0yNiIsImltcGVyc29uYXRlZCI6ZmFsc2UsInV1aWQiOiJjYWE2MDlhYS00MzdjLTQ4N2EtYjJmNS1mODAzN2ZkZmIwMjkiLCJpYXQiOjE3MjQ4NDE2NDd9.qDSpZGFDac-5iXVacM5j79ejkSra3ePpECl750kRJfc";
const getAuthToken = () => Promise.resolve(authToken);

const externalTranslationsUrl =
  Platform.OS !== "web"
    ? "https://backend-for-user.dev.envs.lookiero.tech/api/v2/translations"
    : __DEV__
      ? "/local-to-external-i18n"
      : "/external-i18n";
const translations: EndpointFunction[] = [
  (locale) =>
    translationExternalEndpoint({
      translationsUrl: externalTranslationsUrl,
      projects: [["user-area-front"], ["inventory-catalog", "feature;feature_value;Color"], ["checkout"]],
    })(locale),
];

const useRedirect: () => Record<string, string> = () => ({
  returnUrl: "https://web2.dev.aws.lookiero.es/user/",
});

setPaymentsBridge({
  getToken: getAuthToken,
  stripeKey: "pk_test_6dwdUfkUm7AaUH3oSje9H0kk",
  stripeUkKey:
    "pk_test_51Jw6NEHQ1gSUXaQ8KKBLBhmzw7k0TCTN0GQ6qq8oGRmXCn09ptn7zrHX5jsyidv8iBDGuzUDV3R0fko01m5oJzMO00z6XmoLbn",
  commonAssetsPath: "https://cdn.dev.envs.lookiero.tech/commons",
  paymentsAssetsPath: "https://cdn.dev.envs.lookiero.tech/payments-front",
  useAvoidKeyboard: () => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAvoidKeyboard: () => void 0,
  }),
  emitTrackingEvent: (e) => {
    console.log("Tracking Payment event", e);
  },
  appVersion: VERSION,
  graphqlUri: "/graphql",
  googlePay: {
    merchant: {
      merchantId: "0123456789",
      merchantName: "Lookiero",
    },
    isTestEnv: true,
  },
  useFeatureFlags: () => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    ALLOW_GOOGLE_PAY_AT: true,
    ALLOW_GOOGLE_PAY_BE: true,
    ALLOW_GOOGLE_PAY_DE: true,
    ALLOW_GOOGLE_PAY_ES: true,
    ALLOW_GOOGLE_PAY_FR: true,
    ALLOW_GOOGLE_PAY_GB: true,
    ALLOW_GOOGLE_PAY_IT: true,
    ALLOW_GOOGLE_PAY_LU: true,
    ALLOW_GOOGLE_PAY_NL: true,
    ALLOW_GOOGLE_PAY_PT: true,
    ALLOW_GOOGLE_PAY_APP_AT: true,
    ALLOW_GOOGLE_PAY_APP_BE: true,
    ALLOW_GOOGLE_PAY_APP_DE: true,
    ALLOW_GOOGLE_PAY_APP_ES: true,
    ALLOW_GOOGLE_PAY_APP_FR: true,
    ALLOW_GOOGLE_PAY_APP_GB: true,
    ALLOW_GOOGLE_PAY_APP_IT: true,
    ALLOW_GOOGLE_PAY_APP_LU: true,
    ALLOW_GOOGLE_PAY_APP_NL: true,
    ALLOW_GOOGLE_PAY_APP_PT: true,
    /* eslint-enable @typescript-eslint/naming-convention */
  }),
  locale: () => Promise.resolve("es-ES"),
  scrollView: ScrollView,
});

const kameleoonConfig: KameleoonEnvironment = {
  siteCode: "aplm4v3ckn",
  experiments: {},
};

// const { Component: Messaging } = checkoutMockBootstrap();
const { Component: Messaging } = checkoutBootstrap({ apiUrl: () => apiUrl, getAuthToken });
const I18n = i18n({
  fetchTranslation: fetchTranslations({ translations }),
  contextId: "CheckoutI18n",
});
const Root = root({
  Messaging,
  I18n,
  getAuthToken,
  development: false,
  sentry: () => sentryConfig,
  kameleoon: () => kameleoonConfig,
})({ customerId: customer.customerId });

const ExpoRoot: FC = () => {
  const [fontsLoaded] = useFonts({
    ["AreaInktrap-Semibold"]: require("@lookiero/aurora-fonts/AreaInktrap-Semibold.otf"),
    ["AreaNormal-Semibold"]: require("@lookiero/aurora-fonts/AreaNormal-Semibold.otf"),
    ["AreaNormal-Extrabold"]: require("@lookiero/aurora-fonts/AreaNormal-Extrabold.otf"),
    auroraicons: require("@lookiero/aurora-iconfont/dist/auroraicons.ttf"),
  });

  const [isAccessible, setIsAccessible] = useState<boolean>();
  const onNotAccessible = useCallback(() => setIsAccessible(false), []);

  return fontsLoaded ? (
    <PaymentsQueryProvider>
      <EventProvider>
        <Aurora>
          {isAccessible === false && <Text heading={true}>Checkout is not accessible!</Text>}

          <Router>
            <Routes>
              <Route
                path="/checkout/*"
                element={
                  <Root
                    basePath="/checkout"
                    customer={customer}
                    layout={DummyLayout}
                    locale={locale}
                    order={order}
                    subscription={subscription}
                    useRedirect={useRedirect}
                    onNotAccessible={onNotAccessible}
                  />
                }
              />

              <Route element={<Navigate to="/checkout" replace />} path="*" />
            </Routes>
          </Router>
        </Aurora>
      </EventProvider>
    </PaymentsQueryProvider>
  ) : null;
};

export { ExpoRoot };
